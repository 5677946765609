import { Container, Typography } from '@mui/material'
import { theme } from 'assets/material/theme'
import { Spacer } from 'components/layout/spacer'
import { Support } from 'components/support'
import React from 'react'

export default function PageAdminReports() {
	return (
		<React.Fragment>
			<Container maxWidth={`xs`}>
				<Spacer height={theme.spacing(4)} />
				<Typography variant={`h3`} fontWeight={600} textAlign={`center`}>
					Reports
				</Typography>

				<Typography variant={`medium`} component='div' textAlign={'center'}>
					Value proposition, Lorem ipsum dolor sit amet, lorem ipsum dolor sit amet consectetur.
				</Typography>

				<Spacer height={theme.spacing(4)} />
			</Container>
			<Spacer height={theme.spacing(4)} />
			<Support maxWidth='sm' />
		</React.Fragment>
	)
}
