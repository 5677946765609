import { pick } from 'lodash'
import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'
import { STORE } from '../constants'
import { flw_user, user } from '../constants/user'
import { FlwUser, FlwUserPreRegistrationType, User, UserPreRegistrationType } from '../types/user'

export interface FormStore {
	formData: UserPreRegistrationType
	setStoreData: (_data: Partial<User>) => void
	resetRegisterStoreData: () => void
}

export const initialState: FormStore = {
	formData: pick(user, ['first_name', 'last_name', 'email', 'username', 'contact_number', 'password']),
	setStoreData: () => {},
	resetRegisterStoreData: () => {},
}

export const userRegisterStore = create<FormStore>()(
	devtools(
		persist(
			(set) => ({
				...initialState,
				setStoreData: (data: any) =>
					set((state: Pick<FormStore, 'formData'>) => ({
						formData: { ...state.formData, ...data },
					})),
				resetRegisterStoreData: () => set({ formData: initialState.formData }),
			}),
			{
				name: STORE.USER_REGISTRATION,
				getStorage: () => localStorage,
			}
		)
	)
)

export interface FlwFormStore {
	flwFormData: FlwUserPreRegistrationType
	setFlwStoreData: (_data: Partial<FlwUser>) => void
	resetFlwRegisterStoreData: () => void
}

export const initialFlwState: FlwFormStore = {
	flwFormData: pick(flw_user, ['first_name', 'last_name', 'email', 'username', 'contact_number', 'password']),
	setFlwStoreData: () => {},
	resetFlwRegisterStoreData: () => {},
}

export const flwUserRegisterStore = create<FlwFormStore>()(
	devtools(
		persist(
			(set) => ({
				...initialFlwState,
				setFlwStoreData: (data: any) =>
					set((state: Pick<FlwFormStore, 'flwFormData'>) => ({
						flwFormData: { ...state.flwFormData, ...data },
					})),
				resetFlwRegisterStoreData: () => set({ flwFormData: initialFlwState.flwFormData }),
			}),
			{
				name: STORE.USER_REGISTRATION,
				getStorage: () => localStorage,
			}
		)
	)
)
