import { ThemeProvider } from '@mui/material'
import { theme } from 'assets/material/theme'
import { Provider } from 'context/Provider'
import { Outlet } from 'react-router-dom'
import AdminUnauthenticatedLayout from './admin-unauthenticated-layout'

function AdminRoutesUnauthenticatedLayout() {
	return (
		<ThemeProvider theme={theme}>
			<Provider>
				<AdminUnauthenticatedLayout>
					<Outlet />
				</AdminUnauthenticatedLayout>
			</Provider>
		</ThemeProvider>
	)
}

export default AdminRoutesUnauthenticatedLayout
