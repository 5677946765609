import { Box, Card, CardContent, Container, Grid, Typography, useMediaQuery } from '@mui/material'
import { startCase } from 'lodash'
import * as React from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { theme } from '../../../../../assets/material/theme'
import { colors } from '../../../../../constants'
import field_of_study from '../../../../../constants/field_of_study_za.json'
import universities from '../../../../../constants/universities-za.json'
import { useAppContext } from '../../../../../context/Provider'
import { useAuthService } from '../../../../../services/user'
import { userCurrentStore } from '../../../../../store/userCurrentStore'
import { IconName } from '../../../../../types/common'
import { FlwUser, User } from '../../../../../types/user'
import { generateAcademicYears, sleep } from '../../../../../utils'
import { Button } from '../../../../button/button'
import { Column, Row } from '../../../../grid'
import { Icon } from '../../../../icon'
import { Spacer } from '../../../../layout/spacer'
import { Link } from '../../../../link'
import { Splash } from '../../../../splash'

interface ComponentProps {
	user?: User
}

const Section: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	return <Box marginBottom={theme.spacing(5)}>{children}</Box>
}

const SectionTitle: React.FC<{ title: string; icon: IconName }> = ({ title, icon }) => {
	return (
		<Row justifyContent={`flex-start`} marginBottom={theme.spacing(3)}>
			{icon && <Icon name={icon} width={24} height={24} />}
			<Typography variant={`jumbo`} fontWeight={700} marginLeft={theme.spacing(1)}>
				{title}
			</Typography>
		</Row>
	)
}

const SectionItem: React.FC<{ label: string; value: string; linkUrl?: string; linkTitle?: string }> = ({
	label,
	value,
	linkUrl,
	linkTitle,
}) => {
	return (
		<Column justifyContent={`flex-start`} marginBottom={theme.spacing(2)}>
			<Typography variant={`medium`} fontWeight={600}>
				{label}
			</Typography>
			<Typography variant={`normal`} fontWeight={400}>
				{value}
			</Typography>
			{linkTitle && linkUrl && <Link text={linkTitle} to={linkUrl} />}
		</Column>
	)
}

const Component: React.FC<ComponentProps> = () => {
	const { setDialog } = useAppContext()
	const navigate = useNavigate()
	const { userDestroy, userLogout } = useAuthService()
	const [isDestroying, setIsDestroying] = useState(false)
	const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('md')) ?? true
	const direction = isMobile ? `row` : `row-reverse`
	const academic_years = generateAcademicYears()

	const { currentUser: storeUser } = userCurrentStore()

	const currentUser = { ...storeUser } as FlwUser

	async function deleteAccount() {
		try {
			setIsDestroying(true)
			await userDestroy()

			// Hack: Need to find a better way
			localStorage.setItem('profile-deleted', 'true')

			await sleep(100)
			await userLogout()
		} catch (error) {
			setIsDestroying(false)
		} finally {
			navigate(`/`)
		}
	}

	return isDestroying ? (
		<Splash />
	) : (
		<Container maxWidth={`sm`}>
			<Spacer height={theme.spacing(10)} />
			<Typography variant={`h3`} fontWeight={600} textAlign={`center`}>
				Absa Fellowship
			</Typography>
			<Typography component={`h4`} variant={`large`} textAlign={`center`}>
				Here you can update all your personal, contact, security and profile related information
			</Typography>
			<Spacer height={theme.spacing(5)} />
			<Card style={{ borderLeft: `4px solid ${colors.brand_graphite_35}` }}>
				<CardContent>
					<Column>
						<Grid container={true} sx={{ flexDirection: direction }}>
							<Grid item={true} xs={12} md={12}>
								<Spacer height={theme.spacing(3)} />
								<Typography variant={`h4`} fontWeight={700}>
									Year 1 - 2023
								</Typography>
								<Typography variant={`normal`}>Lorem ipsum dolor sit amet</Typography>
							</Grid>
							<Grid item={true} xs={12} md={6}>
								<Spacer height={theme.spacing(3)} />
								<Typography variant={`h3`} fontWeight={700} margin={0}>
									10
								</Typography>
								<Typography variant={`normal`}>Registered</Typography>
							</Grid>
							<Grid item={true} xs={12} md={6}>
								<Spacer height={theme.spacing(3)} />
								<Typography variant={`h3`} fontWeight={700} margin={0}>
									0
								</Typography>
								<Typography variant={`normal`}>Completed</Typography>
							</Grid>
							<Grid item={true} xs={12} md={12}>
								<Spacer height={theme.spacing(3)} />
								<Typography variant={`normal`} display={`flex`} alignContent={`center`}>
									<Icon name={`hourglass`} color={colors.brand_calm} width={21} height={21} /> Not started
								</Typography>
							</Grid>
							<Grid item={true} xs={12} md={12}>
								<Spacer height={theme.spacing(3)} />
								<Link text={`See learning journey`} to={`/fellowship/apply`} bold />
							</Grid>
							<Spacer height={theme.spacing(3)} />
							<Grid item={true} xs={12} md={12} display={`flex`} alignContent={`center`} alignItems={`center`}>
								<Box
									marginRight={theme.spacing(1)}
									border={`4px solid ${colors.brand_passion}`}
									borderRadius={theme.spacing(10)}
									padding={theme.spacing(1)}
									width={30}
									height={30}
									display={`flex`}
									justifyContent={`center`}
									alignItems={`center`}>
									<Icon name={`subject`} color={colors.brand_calm} width={21} height={21} />
								</Box>
								<div>
									<Typography variant={`h4`} margin={0}>
										Module abc
									</Typography>
									<Typography variant={`normal`} display={`flex`} alignContent={`center`}>
										Module abc
									</Typography>
								</div>
							</Grid>
							<Grid item={true} xs={12} md={12}>
								<Spacer height={theme.spacing(3)} />
								<Button variant={`outlined`} color={`warning`} style={{ borderRadius: 4 }}>
									Start module
								</Button>
							</Grid>
						</Grid>
					</Column>
				</CardContent>
			</Card>
			<Spacer height={theme.spacing(4)} />
			<Section>
				<SectionTitle title={'Personal information'} icon={'contactCard'} />
				<SectionItem label={`First name(s)`} value={currentUser.first_name} />
				<SectionItem label={`Last name`} value={currentUser.last_name} />
				<Spacer height={theme.spacing(1)} />
				<Button variant={`outlined`} color={`secondary`} onClick={() => navigate(`/fellowship/account/update`)}>
					Update
				</Button>
			</Section>
			<Section>
				<SectionTitle title={'Contact info & password'} icon={'contactCard'} />
				{currentUser.email && (
					<SectionItem
						label={`Email Address`}
						value={currentUser.email}
						linkTitle={`Edit`}
						linkUrl={`/fellowship/account/update-email`}
					/>
				)}
				{currentUser.contact_number && (
					<SectionItem
						label={`Contact number`}
						value={currentUser.contact_number}
						linkTitle={`Edit`}
						linkUrl={`/fellowship/account/update-contact-number`}
					/>
				)}
				<SectionItem
					label={`Password`}
					value={`**********`}
					linkTitle={`Update password`}
					linkUrl={`/fellowship/account/update-password`}
				/>
			</Section>
			<Section>
				<SectionTitle title={'Profile information'} icon={'contactCard'} />
				{currentUser.gender && <SectionItem label={`Gender`} value={startCase(currentUser.gender)} />}
				{currentUser.dob && <SectionItem label={`Date of birth`} value={currentUser.dob} />}
				{currentUser.country && <SectionItem label={`Country of residence`} value={currentUser.country} />}
				<SectionItem label={`University`} value={universities.find((option) => option.key === currentUser?.university)?.label} />
				<SectionItem
					label={`Field of Study`}
					value={field_of_study.find((option) => option.key === currentUser?.degree_field)?.label}
				/>
				<SectionItem label={`Degree Name`} value={currentUser?.degree_name} />
				<SectionItem
					label={`Academic Year`}
					value={academic_years.find((option) => option.value === currentUser?.academic_year)?.label || 'Academic Year not set'}
				/>
				{currentUser.country_citizenship && (
					<SectionItem
						label={`South African citizen`}
						value={startCase(String(currentUser.country_citizenship === `South Africa` ? 'Yes' : 'No'))}
					/>
				)}
				{currentUser.race && currentUser.sa_citizen && <SectionItem label={`Ethnicity`} value={startCase(currentUser.race)} />}
				<Spacer height={theme.spacing(1)} />
				<Button variant={`outlined`} color={`secondary`} onClick={() => navigate(`/fellowship/account/update`)}>
					Update
				</Button>
				<Spacer height={theme.spacing(1)} />
				<Button
					variant={`text`}
					color={`secondary`}
					isLoading={isDestroying}
					onClick={() => {
						setDialog({
							open: true,
							title: `Delete account`,
							description: `If you delete this account, your access to both Consumer Financial Education and ReadytoWork will be removed.`,
							onConfirm: deleteAccount,
							setDialog,
							confirmTitle: 'Delete',
							variant: 'error',
						})
					}}>
					Delete my account
				</Button>
			</Section>
			<Spacer height={theme.spacing(6)} />
		</Container>
	)
}

export default Component
