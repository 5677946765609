import { Container } from '@mui/material'
import { Box } from 'components/box/box'
import { Support } from 'components/support'
import { ViewFellowshipProfile } from 'components/views/single/fellowship_profile'

export default function PageFellowshipAccountHome() {
	return (
		<Box display='flex' flexDirection='column' minHeight='100%'>
			<Box flexGrow={1}>
				<Container maxWidth={`md`}>
					<ViewFellowshipProfile />
				</Container>
			</Box>
			<Support maxWidth='xl' type='flw' />
		</Box>
	)
}
