export const useStorage = () => {
	const setStorage = async (key: string, payload: any): Promise<void> => {
		const parsed_params = typeof payload !== 'string' ? JSON.stringify(payload) : payload
		const storage = await Promise.resolve(localStorage.setItem(key, parsed_params))
		return storage
	}

	const getStorage = async (key: string): Promise<any> => {
		const storage = await Promise.resolve(localStorage.getItem(key))
		return JSON.parse(storage || '{}')
	}

	const removeStorage = async (key: string): Promise<void> => {
		const storage = await Promise.resolve(localStorage.removeItem(key))
		return storage
	}

	return {
		setStorage,
		getStorage,
		removeStorage,
	}
}
