import { Grid } from '@mui/material'
import { Password } from 'components/form-controlled-field/password'
import { useAppContext } from 'context/Provider'
import { Formik } from 'formik'
import { errorMessages } from 'message/errorMessages'
import * as React from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useAuthService } from 'services/user'
import { sizing } from '../../../../constants'
import { Button } from '../../../button/button'
import { Form } from '../../../form-controlled-field/form'
import { FormTitle } from '../../../form-controlled-field/title'
import { Spacer } from '../../../layout/spacer'
import { schema } from './src/schema'

interface FormAuthSetPasswordProps {
	fellowship?: boolean
}

export const FormSetResetPassword: React.FC<FormAuthSetPasswordProps> = ({ fellowship = false }) => {
	const [searchParams] = useSearchParams()
	const { userSetResetPassword, userFellowSetResetPassword } = useAuthService()
	const { setSnackbar } = useAppContext()
	const navigate = useNavigate()

	const isEmpty = (value: string) => {
		return value.length === 0
	}

	return (
		<React.Fragment>
			<Spacer height={60} />
			<Formik
				enableReinitialize={true}
				initialValues={{ password: '', confirmPassword: '', submitted: false }}
				validationSchema={schema}
				onSubmit={async (values, { setStatus, setSubmitting, setFieldError }) => {
					const token = searchParams.get('token')
					const _func = fellowship ? userFellowSetResetPassword : userSetResetPassword
					const output = await _func(values['password'], token)
					// Hack: Look into sharing snackbar/toaster messages across pages
					if (output['error']) {
						setSnackbar({
							open: true,
							message: output['message'],
							severity: `error`,
						})
					} else {
						localStorage.setItem('reset-password', 'true')
						navigate(fellowship ? '/fellowship/login' : '/login')
					}
				}}>
				{({ status, isSubmitting, values }) => {
					return (
						<React.Fragment>
							<FormTitle
								title={`Reset your password`}
								description={`A reset email will be sent to this email address with further instructions.`}
								status={status}
							/>
							<Form>
								<Password
									name={`password`}
									label={`New Password`}
									placeholder='Create a strong password'
									hint={isEmpty(values['password']) ? null : errorMessages.user.PASSWORD_SPEC}
								/>
								<Password name={`confirmPassword`} label={`Confirm Password`} placeholder='Confirm your password' />
								<Spacer height={sizing.themeSpacing * 3} />
								<Grid container={true} spacing={1}>
									<Grid item={true} xs={12}>
										<Button type={`submit`} isLoading={isSubmitting} variant={`contained`} color={`secondary`}>
											Reset password
										</Button>
									</Grid>
									<Grid item={true} xs={12}>
										<Button type={`button`} variant={`text`} color={`secondary`} onClick={() => navigate(-1)}>
											Cancel
										</Button>
									</Grid>
								</Grid>
							</Form>
						</React.Fragment>
					)
				}}
			</Formik>
			<Spacer height={200} />
		</React.Fragment>
	)
}
