import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { admin_routes, routes as navigationRoutes } from '../navigation/navigation'

export const useRoutes = () => {
	const [routesLoaded, setRoutesLoaded] = useState(false)
	const [routes, setRoutes] = useState([])
	const [adminRoutes, setAdminRoutes] = useState([])
	const { pathname } = useLocation()
	const getPath = (path: string) => (path.startsWith('/') ? path.substring(1) : path)

	const handleRouteMapping = () => {
		const _routes = navigationRoutes.map((item) => ({
			...item,
			focus: getPath(pathname) === getPath(item?.path ?? ``),
		}))

		setRoutes(_routes)

		const _admin_routes = admin_routes.map((item) => ({
			...item,
			focus: getPath(pathname) === getPath(item?.path ?? ``),
		}))

		setAdminRoutes(_admin_routes)
		setRoutesLoaded(true)
	}

	useEffect(() => {
		handleRouteMapping()
	}, [])

	return {
		routes,
		adminroutes: adminRoutes,
		pathname,
		routesLoaded,
	}
}
