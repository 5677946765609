import Box from '@mui/material/Box'
import MuiDrawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import { CSSObject, styled, Theme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { Icon } from 'components/icon'
import { Link } from 'components/link'
import { useRoutes } from 'hooks/useRoutes'
import * as React from 'react'
import { memo, useEffect, useState } from 'react'
import { RouteProps } from 'types/common'

const drawerWidth = 240

const openedMixin = (theme: Theme): CSSObject => ({
	width: drawerWidth,
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: 'hidden',
})

const closedMixin = (theme: Theme): CSSObject => ({
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	overflowX: 'hidden',
	width: `calc(${theme.spacing(7)} + 1px)`,
	[theme.breakpoints.up('sm')]: {
		width: `calc(${theme.spacing(8)} + 1px)`,
	},
})

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme }) => ({
	width: drawerWidth,
	flexShrink: 0,
	whiteSpace: 'nowrap',
	boxSizing: 'border-box',
	variants: [
		{
			props: ({ open }) => open,
			style: {
				...openedMixin(theme),
				'& .MuiDrawer-paper': openedMixin(theme),
			},
		},
		{
			props: ({ open }) => !open,
			style: {
				...closedMixin(theme),
				'& .MuiDrawer-paper': closedMixin(theme),
			},
		},
	],
}))

const Component: React.FC = () => {
	const [open] = useState(false)

	const { adminroutes, routesLoaded, pathname } = useRoutes()

	const [adminItems, setAdminItems] = useState([])

	const orderSideMenu = () => {
		const result: any[] = []
		adminroutes.forEach((o) => {
			if (o?.placement?.includes(`aside`)) {
				o.children.forEach((c: any) => {
					result.push(c)
				})
			}
		})

		setAdminItems([...result])
	}

	useEffect(() => {
		if (!routesLoaded) {
			return
		}

		orderSideMenu()
	}, [adminroutes, routesLoaded])

	return (
		<Box sx={{ display: 'flex' }}>
			<Drawer variant='permanent' open={open} className='mini-drawer'>
				<List>
					{adminItems.map(({ label, icon, path, focus }: RouteProps, i: number) => (
						<ListItem
							key={i + 1}
							disablePadding
							sx={{ display: 'block' }}
							className={`${pathname.includes(path) ? 'active' : ''}`}>
							<ListItemButton
								sx={[
									{
										minHeight: 50,
										px: 2.5,
										justifyContent: 'center',
									},
								]}>
								<Link key={i + 1} to={path}>
									<ListItemText
										sx={{
											display: 'flex',
											alignItems: 'center',
											alignContent: 'center',
											justifyContent: 'center',
											flexDirection: 'column',
											textAlign: 'center',
											fontSize: 10,
										}}>
										<Icon name={icon} width={22} />
										<Typography variant={`body1`} fontWeight={600} fontSize={10}>
											{label}
										</Typography>
									</ListItemText>
								</Link>
							</ListItemButton>
						</ListItem>
					))}
				</List>
			</Drawer>
		</Box>
	)
}

export default memo(Component)
