import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { scrollToTop } from 'utils'
import { colors, sizing } from '../../constants'
import { routes } from '../../navigation/navigation'
import { userCurrentStore } from '../../store/userCurrentStore'
import { Box } from '../box/box'

interface LayoutProps {
	readonly children: React.ReactNode
}

const AdminUnauthenticatedLayout: React.FC<LayoutProps> = (props) => {
	const { children } = props

	const { pathname } = useLocation()
	const { currentUser } = userCurrentStore()
	const navigate = useNavigate()

	useEffect(() => {
		const route = routes.find((route) => route.path === pathname)
		if (route?.display?.includes(`private`) && !currentUser) {
			navigate(`/admin-auth/login`)
		} else {
			scrollToTop()
		}
	}, [pathname])

	return (
		<Box display={`flex`} flexDirection={`column`} className={`absa-cdp-ce-ui`} minHeight='100%'>
			<Box flexGrow={1} display='flex' backgroundColor={colors.bgSurface}>
				<Box flexGrow={1} component={`main`} backgroundColor={colors.bgSurface}>
					<Box height={sizing.headerHeight} />
					{children}
				</Box>
			</Box>
		</Box>
	)
}

export default AdminUnauthenticatedLayout
