import { Grid, useMediaQuery } from '@mui/material'
import { Formik } from 'formik'
import { useStorage } from 'hooks/useStorage'
import * as React from 'react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { FormTitle } from '../../../../components/form-controlled-field/title'
import { sizing } from '../../../../constants'
import { useAppContext } from '../../../../context/Provider'
import { errorMessages } from '../../../../message/errorMessages'
import { useAuthService } from '../../../../services/user'
import { flwUserRegisterStore, userRegisterStore } from '../../../../store/userRegisterStore'
import { Button } from '../../../button/button'
import { Form } from '../../../form-controlled-field/form'
import { Password } from '../../../form-controlled-field/password'
import { Phone } from '../../../form-controlled-field/phone'
import { TextField } from '../../../form-controlled-field/text'
import { Spacer } from '../../../layout/spacer'
import { schema } from './src/schema'

interface FormAuthRegisterProps {
	fellowship?: boolean
}

export const FormAuthRegister = ({ fellowship = false }: FormAuthRegisterProps) => {
	const { getStorage } = useStorage()
	const navigate = useNavigate()
	const { setGlobalLoader, setSnackbar, setDialog } = useAppContext()
	const { formData, resetRegisterStoreData } = userRegisterStore()
	const { flwFormData, setFlwStoreData, resetFlwRegisterStoreData } = flwUserRegisterStore()
	const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('md')) ?? true
	const { userCreate, userFellowCreate } = useAuthService()

	const handleFlwInviteParams = async () => {
		const _flw = await getStorage('flw_response')
		if (_flw) {
			const { email: flwEmail } = _flw
			if (fellowship) {
				setFlwStoreData({ email: flwEmail, username: flwEmail, user_type_raw: 'flw' })
			}
		}
	}

	useEffect(() => {
		if (fellowship) {
			handleFlwInviteParams()
		}
	}, [])

	return (
		<React.Fragment>
			<Spacer height={60} />
			<Formik
				enableReinitialize={true}
				initialValues={fellowship ? flwFormData : formData}
				validationSchema={schema}
				onSubmit={(values, { setStatus, setSubmitting, setFieldError }) => {
					setGlobalLoader(true)
					const _func = fellowship
						? userFellowCreate({ ...values, username: values.email, user_type_raw: 'flw' })
						: userCreate({ ...values })
					_func.then(async (res: any) => {
						setGlobalLoader(false)
						if (res?.error) {
							setStatus({
								error: res?.message,
							})
							setSubmitting(false)
							setSnackbar({
								open: true,
								message: res?.message,
								severity: `error`,
							})
						} else if (res?.email_exists) {
							setStatus({
								error: errorMessages.user.EMAIL_EXISTS,
							})
							setSubmitting(false)
							const errorMessage =
								res.confirmed === false
									? `You have not completed the OTP process required to activate your account, login to complete it`
									: errorMessages.user.EMAIL_EXISTS
							setDialog({
								open: true,
								title: `Existing account`,
								description: errorMessage,
								onConfirm: () => navigate('/login'),
								setDialog,
								confirmTitle: 'Go to login',
								variant: 'info',
							})
						} else if (res?.email_exists || res?.contact_number_exists) {
							if (res?.email_exists) {
								setFieldError(`email`, errorMessages.user.EMAIL_EXISTS)
							}

							if (res?.contact_number_exists) {
								setFieldError(`contact_number`, errorMessages.user.CONTACT_NUMBER_EXISTS)
							}

							setStatus({
								error: errorMessages.user.ACCOUNT_EXISTS,
							})
							setSnackbar({
								open: true,
								message: errorMessages.user.ACCOUNT_EXISTS,
								severity: `error`,
							})
							setSubmitting(false)
							return null
						} else {
							navigate(fellowship ? `/fellowship/register/information` : `/register/information`)
						}
					})
				}}>
				{({ status, isSubmitting, handleReset }) => {
					return (
						<React.Fragment>
							<FormTitle
								title={`Create an account`}
								subTitle={`Profile details`}
								nextStep={`Profile information`}
								progress={55}
								status={status}
							/>
							<Form>
								<TextField name={`first_name`} label={`First Name(s)`} placeholder={`e.g Johnathan`} />
								<TextField name={`last_name`} label={`Last Name`} placeholder={`e.g Smith`} />
								<TextField
									trim
									name={`email`}
									label={`Email Address`}
									placeholder={`johnathansmith@gmail.com`}
									disabled={fellowship}
								/>
								<Phone name={`contact_number`} label={`Contact number`} placeholder={`00 000 0000`} />
								<Password
									name={`password`}
									label={`Password`}
									hint={errorMessages.user.PASSWORD_SPEC}
									placeholder={`Create a strong password`}
								/>
								<Spacer height={sizing.themeSpacing * 3} />
								<Grid container={true} spacing={1} sx={{ flexDirection: isMobile ? `row` : `row-reverse` }}>
									<Grid item={true} xs={12} md={6}>
										<Button type={`submit`} isLoading={isSubmitting} variant={`contained`} color={`secondary`}>
											Next
										</Button>
									</Grid>
									<Grid item={true} xs={12} md={6}>
										<Button
											type={`button`}
											variant={`outlined`}
											color={`secondary`}
											onClick={() => {
												handleReset()
												if (fellowship) {
													resetFlwRegisterStoreData()
													navigate(-1)
												} else {
													resetRegisterStoreData()
													navigate('/')
												}
											}}>
											Cancel
										</Button>
									</Grid>
								</Grid>
							</Form>
						</React.Fragment>
					)
				}}
			</Formik>
			<Spacer height={200} />
		</React.Fragment>
	)
}
