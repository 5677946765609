import { Container } from '@mui/material'
import { Box } from 'components/box/box'
import { FormUpdateProfile } from 'components/form-controlled/account/profile/form'
import { Support } from 'components/support'

export default function PageFellowshipAccountUpdateProfile() {
	return (
		<Box display='flex' flexDirection='column' minHeight='100%'>
			<Box>
				<Container maxWidth={`xs`}>
					<FormUpdateProfile fellowship={true} />
				</Container>
			</Box>
			<Support maxWidth='xl' type='flw' />
		</Box>
	)
}
