import { Container } from '@mui/material'
import { FormUpdateDetails } from 'components/form-controlled/account/update/form'
import { RegistrationFooter } from 'pages/auth/components/register-footer'
import React from 'react'

export default function PageFellowshipAccountUpdateDetails() {
	return (
		<React.Fragment>
			<Container maxWidth={`xs`}>
				<FormUpdateDetails fellowship={true} />
			</Container>
			<RegistrationFooter />
		</React.Fragment>
	)
}
