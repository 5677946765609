import { Grid } from '@mui/material'
import { Link } from 'components/link'
import { Formik } from 'formik'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { obfusticateEmail } from 'utils'
import { sizing } from '../../../../constants'
import { Button } from '../../../button/button'
import { Form } from '../../../form-controlled-field/form'
import { TextField } from '../../../form-controlled-field/text'
import { FormTitle } from '../../../form-controlled-field/title'
import { Spacer } from '../../../layout/spacer'
import { useResetPassword } from '../useForgotPassword'
import { schema } from './src/schema'

interface FormAuthResetPasswordProps {
	fellowship?: boolean
}

export const FormResetPassword: React.FC<FormAuthResetPasswordProps> = ({ fellowship = false }) => {
	const { handleSubmit } = useResetPassword({ fellowship })
	const navigate = useNavigate()

	return (
		<React.Fragment>
			<Spacer height={60} />
			<Formik
				enableReinitialize={true}
				initialValues={{ email: '', submitted: false }}
				validationSchema={schema}
				onSubmit={handleSubmit}>
				{({ status, isSubmitting, values }) => {
					if (values['submitted']) {
						const descriptionNode = (
							<div style={{ textAlign: 'start' }}>
								<div> We have sent you an email to {obfusticateEmail(values.email)} with the reset instructions.</div>
								<br />
								<div>
									If you don't have access to this email address, contact support at{' '}
									<Link
										to='mailto:AbsaDigitalhub.support@cloudsmith.co.za'
										text='AbsaDigitalhub.support@cloudsmith.co.za'
									/>
									, or create a <Link to='/register' text='new account' />.
								</div>
							</div>
						)

						return <FormTitle title={`Reset email sent`} description={descriptionNode} status={status} />
					}

					return (
						<React.Fragment>
							<FormTitle
								title={`Reset your password`}
								description={`A reset email will be sent to this email address with further instructions.`}
								status={status}
							/>
							<Form>
								<TextField trim name={`email`} label={`Email Address`} placeholder={`Enter your email Address`} />
								<Spacer height={sizing.themeSpacing * 3} />
								<Grid container={true} spacing={1}>
									<Grid item={true} xs={12}>
										<Button type={`submit`} isLoading={isSubmitting} variant={`contained`} color={`secondary`}>
											Reset password
										</Button>
									</Grid>
									<Grid item={true} xs={12}>
										<Button type={`button`} variant={`outlined`} color={`secondary`} onClick={() => navigate(-1)}>
											Cancel
										</Button>
									</Grid>
								</Grid>
							</Form>
						</React.Fragment>
					)
				}}
			</Formik>
			<Spacer height={200} />
		</React.Fragment>
	)
}
