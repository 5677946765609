import { Autocomplete, TextField, Typography } from '@mui/material'
import { FormikErrors, getIn, useFormikContext } from 'formik'
import * as React from 'react'
import { memo } from 'react'
import { Option } from 'types/common'
import { generateAcademicYears } from 'utils'
import { sizing } from '../../../../constants'
import { Box } from '../../../box/box'
import { Icon } from '../../../icon'
import { InputFieldWrapper } from '../../wrapper'

export type SetFieldValueForFormik = (field: string, value: any, shouldValidate?: boolean) => Promise<void | FormikErrors<unknown>>
export type SetValuesForFormik = (values: unknown, shouldValidate?: boolean) => Promise<void | FormikErrors<unknown>>
export type AfterChange = (
	name: string,
	value: any,
	setFieldValue: SetFieldValueForFormik,
	oldValues: any,
	setValues: SetValuesForFormik
) => void

const options: Option[] = generateAcademicYears()

interface FieldProps {
	name: string
	label?: React.ReactNode | string
	placeholder?: string
	hint?: React.ReactNode | string
	defaultValue?: string
	allowedCountries?: string[]
	afterChange?: AfterChange
	disabled?: boolean
}

const Component: React.FC<FieldProps> = ({ disabled, name, hint, label, placeholder, defaultValue = ``, afterChange }) => {
	const { values, errors, touched, setFieldValue, setValues } = useFormikContext()
	const value = getIn(values, name) || defaultValue
	const error = getIn(errors, name)
	const touch = getIn(touched, name)

	function handleChange(_event: any, option: Option | null) {
		setFieldValue(name, option ? option.value : '').then(() => {
			if (afterChange) {
				afterChange(name, option ? option.value : '', setFieldValue, values, setValues)
			}
		})
	}

	return (
		<InputFieldWrapper name={name} hint={hint} label={label}>
			<Box minHeight={`${sizing.inputHeight}px`}>
				<Autocomplete
					noOptionsText='Not an option'
					disabled={disabled}
					onChange={handleChange}
					options={options}
					disableClearable
					getOptionLabel={(option: any) => option.label}
					value={options.find((option) => option.value === value)}
					sx={{ padding: 0 }}
					className={`-cdp-ce-ui-country`}
					renderOption={(props, o: Option) => {
						return (
							// @ts-ignore
							<Box {...props}>
								<Typography>{o.label}</Typography>
							</Box>
						)
					}}
					popupIcon={<Icon name={`caretDown`} width={`14px`} height={`14px`} />}
					renderInput={(params: any) => {
						return (
							<TextField
								disabled={disabled}
								autoComplete={Math.random().toString()}
								value={value}
								name={name}
								variant={`outlined`}
								placeholder={placeholder ?? label ?? `Select`}
								error={touch && error}
								InputProps={{
									...params.InputProps,
								}}
								{...params}
								className={`m-0 p-0`}
							/>
						)
					}}
				/>
			</Box>
		</InputFieldWrapper>
	)
}

export default memo(Component)
