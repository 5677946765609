import { InputAdornment, TextField } from '@mui/material'
import { getIn, useFormikContext } from 'formik'
import { startCase } from 'lodash'
import * as React from 'react'
import { memo, useState } from 'react'
import { FiEye, FiEyeOff } from 'react-icons/fi'
import { IconButton } from '../../../button/icon-button'
import { InputFieldWrapper } from '../../wrapper'
import { PasswordStrengthBar } from './Component.strength'

interface FieldProps {
	name: string
	label?: React.ReactNode | string
	placeholder?: string
	hint?: React.ReactNode | string
	showStrength?: boolean
}

const Component: React.FC<FieldProps> = ({ name, hint, label, placeholder, showStrength = true }) => {
	const { values, errors, touched, setFieldValue } = useFormikContext()
	const [showPassword, setShowPassword] = useState<boolean>(false)
	const value = getIn(values, name) ?? ''
	const error = getIn(errors, name)
	const touch = getIn(touched, name)

	return (
		<InputFieldWrapper name={name} hint={hint} label={label}>
			<TextField
				fullWidth={true}
				value={value}
				type={showPassword ? 'text' : 'password'}
				name={name}
				error={touch && error}
				placeholder={placeholder ?? startCase(name)}
				autoComplete={`off`}
				onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
					setFieldValue(name, event.target.value)
				}}
				InputProps={{
					endAdornment: (
						<InputAdornment position={`end`}>
							<IconButton aria-label={`toggle password visibility`} onClick={() => setShowPassword(!showPassword)} edge='end'>
								{showPassword ? <FiEye size={18} /> : <FiEyeOff size={18} />}
							</IconButton>
						</InputAdornment>
					),
				}}
			/>
			{showStrength && <PasswordStrengthBar password={value} />}
		</InputFieldWrapper>
	)
}

export default memo(Component)
