import { Drawer, Hidden } from '@mui/material'
import { AdminHeader } from 'components/header'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { scrollToTop } from 'utils'
import { colors, sizing } from '../../constants'
import { styles } from '../../helpers/styles'
import { routes } from '../../navigation/navigation'
import { userCurrentStore } from '../../store/userCurrentStore'
import { Box } from '../box/box'
import { Footer } from '../footer/footer'
import { Sidebar } from '../sidebar'

interface LayoutProps {
	readonly children: React.ReactNode
}

const AdminLayout: React.FC<LayoutProps> = (props) => {
	const { children } = props
	const [mobileOpen, setMobileOpen] = useState(false)

	const mobileMenuToggle = () => {
		setMobileOpen(!mobileOpen)
	}

	const { pathname } = useLocation()
	const { currentUser } = userCurrentStore()
	const navigate = useNavigate()

	useEffect(() => {
		const route = routes.find((route) => route.path === pathname)
		if (route?.display?.includes(`private`) && !currentUser) {
			navigate(`/admin-auth/login`)
		} else {
			scrollToTop()
		}
	}, [pathname])

	return (
		<Box display={`flex`} flexDirection={`column`} className={`absa-cdp-ce-ui`} minHeight='100%'>
			<AdminHeader mobileMenuToggle={mobileMenuToggle} mobileMenuOpen={mobileOpen} />
			<Box flexGrow={1} display='flex' backgroundColor={colors.bgSurface}>
				<Hidden implementation={`css`} mdUp>
					<Drawer variant={`temporary`} anchor={`left`} open={mobileOpen} onClose={mobileMenuToggle} sx={styles.sidebar}>
						<Sidebar toggleDrawer={mobileMenuToggle} />
					</Drawer>
				</Hidden>
				<Box flexGrow={1} component={`main`} backgroundColor={colors.bgSurface}>
					<Box height={sizing.headerHeight} />
					{children}
				</Box>
			</Box>
			<Footer />
		</Box>
	)
}

export default AdminLayout
