import { Container } from '@mui/material'
import { FormAuthRegisterInformation } from 'components/form-controlled/register/information/form'
import { RegistrationFooter } from 'pages/auth/components/register-footer'
import React from 'react'

export default function PageRegisterFellowshipInformation() {
	return (
		<React.Fragment>
			<Container maxWidth={`xs`}>
				<FormAuthRegisterInformation fellowship={true} />
			</Container>
			<RegistrationFooter />
		</React.Fragment>
	)
}
