import { Button } from 'components/button/button'
import { Link } from 'components/link'
import * as React from 'react'
import { memo } from 'react'
import { Box } from '../../../components/box/box'

interface ComponentInterface {
	title: string
	showButton?: boolean
	btnText?: string
	btnLink?: string
}

const Component: React.FC<ComponentInterface> = ({ title = '', showButton = false, btnText, btnLink }) => {
	return (
		<Box className='banner'>
			<Box className='banner-container'>
				<h1 className='banner-heading'>{title}</h1>
				{showButton && (
					<Button variant={`outlined`} color={`error`} fullWidth={false} style={{ marginRight: 15, backgroundColor: '#fff' }}>
						<Link to={btnLink}>{btnText}</Link>
					</Button>
				)}
			</Box>
		</Box>
	)
}

export default memo(Component)
