import { AxiosError } from 'axios'
import { jwtDecode } from 'jwt-decode'
import { errorMessages } from '../message/errorMessages'
import { userCurrentStore } from '../store/userCurrentStore'
import { flwUserRegisterStore, userRegisterStore } from '../store/userRegisterStore'
import { ServerError } from '../types/common'
import {
	FlwUserPreRegistrationType,
	User,
	UserFlwRegistrationType,
	UserLoginType,
	UserPreRegistrationType,
	UserRegistrationType,
	UserTokenType,
	UserValidateType,
} from '../types/user'
import { cleanContactNumber, getToken, sleep } from '../utils'
import { TOKEN_TAG } from './../constants'
import { errorHandler } from './errorHandler'
import { Api } from './http'

export const useAuthService = () => {
	const { removeCurrentUser, setCurrentUser } = userCurrentStore()
	const { resetRegisterStoreData, setStoreData } = userRegisterStore()
	const { setFlwStoreData } = flwUserRegisterStore()

	const handleCreateUser = async (path: string, payload: any) => {
		setFlwStoreData(payload)
		return Api.post(path, payload)
			.then((response) => {
				return {
					...response?.data,
					message: `Fellowship Account is ready for registration`,
				}
			})
			.catch((error: AxiosError<ServerError>) => {
				return errorHandler(error)
			})
	}

	async function userCreate(payload: UserPreRegistrationType) {
		setStoreData(payload)
		return await handleCreateUser(`/v2/ce_user/search/`, payload)
	}

	async function userFellowCreate(payload: FlwUserPreRegistrationType) {
		setFlwStoreData(payload)
		return await handleCreateUser(`/v4/flw_user/search/`, payload)
	}

	const handleUserCreateInformation = async (path: string, payload: any) => {
		return Api.post(path, payload)
			.then((response) => {
				return {
					...response.data,
					message: `Account created successfully`,
				}
			})
			.catch((error: AxiosError<ServerError>) => {
				return errorHandler(error)
			})
	}

	async function userCreateInformation(payload: UserPreRegistrationType & UserRegistrationType) {
		return handleUserCreateInformation(`/v2/ce_user_create/`, payload)
	}

	async function userFellowshipCreateInformation(payload: FlwUserPreRegistrationType & UserFlwRegistrationType) {
		return handleUserCreateInformation(`/v4/flw_user_create/`, payload)
	}

	async function userCreateConfirm(payload: UserLoginType) {
		return Api.post(`/v2​/ce_user​/confirm_user​/`, payload)
			.then(async (response) => {
				return {
					...response.data,
					message: `Registration confirmed successfully`,
				}
			})
			.catch((error: AxiosError<ServerError>) => {
				return errorHandler(
					error,
					// @ts-ignore
					error?.response.data?.detail ?? errorMessages.user.ACCOUNT_NOT_EXISTS
				)
			})
	}

	async function userFlwLogin(payload: UserLoginType) {
		try {
			await userLogout()
			await sleep(1000)
			const response = await Api.post(`/api/token/`, payload)
			if (response?.data?.access) {
				const { user_id } = { ...jwtDecode(response?.data?.access) } as UserTokenType
				localStorage.setItem(
					TOKEN_TAG,
					JSON.stringify({
						...response?.data,
						created_at: new Date().toISOString(),
						remember_me: !!payload?.remember_me,
						user_id,
					})
				)
				await sleep(1000)
				const user = await userFlwProfile(user_id)

				if (user) {
					const unconfirmed = user?.error && user.error === 403
					if (unconfirmed) {
						localStorage.removeItem(TOKEN_TAG)
					}
					return {
						...response?.data,
						message: `User login successful`,
						unconfirmed,
					}
				}
			}
			throw new Error(errorMessages.user.ACCOUNT_NOT_EXISTS)
		} catch (error: any) {
			console.log('res:', error?.response)
			if (error?.response?.status === 403) {
				return errorHandler(error, error?.response?.data)
			} else {
				return errorHandler(error, error?.response?.data?.detail ?? errorMessages.user.ACCOUNT_NOT_EXISTS)
			}
		}
	}

	async function userLogin(payload: UserLoginType) {
		try {
			await userLogout()
			await sleep(1000)
			const response = await Api.post(`/api/token/`, payload)
			if (response?.data?.access) {
				const { user_id } = { ...jwtDecode(response?.data?.access) } as UserTokenType
				localStorage.setItem(
					TOKEN_TAG,
					JSON.stringify({
						...response?.data,
						created_at: new Date().toISOString(),
						remember_me: !!payload?.remember_me,
						user_id,
					})
				)
				await sleep(1000)
				const _func = response.data.user_type.includes('flw') ? userFlwProfile : userProfile
				const user = await _func(user_id)

				if (user) {
					const unconfirmed = user?.error && user.error === 403
					if (unconfirmed) {
						localStorage.removeItem(TOKEN_TAG)
					}
					return {
						...response?.data,
						message: `User login successful`,
						unconfirmed,
					}
				}
			}
			throw new Error(errorMessages.user.ACCOUNT_NOT_EXISTS)
		} catch (error: any) {
			console.log('res:', error?.response)
			if (error?.response?.status === 403) {
				return errorHandler(error, error?.response?.data)
			} else {
				return errorHandler(error, error?.response?.data?.detail ?? errorMessages.user.ACCOUNT_NOT_EXISTS)
			}
		}
	}

	const handleUserProfile = async (path: string) => {
		return Api.get(path)
			.then(async (response) => {
				if (response?.data?.id) {
					const data = !response
						? null
						: { ...response.data, contact_number: cleanContactNumber(response.data, response.data['contact_number']) }
					setCurrentUser(data)
					await sleep(1000)
					return data
				} else {
					return null
				}
			})
			.catch((error: AxiosError<ServerError>) => {
				console.error(error)
				return errorHandler(error)
			})
	}

	async function userFlwProfile(user_id?: string) {
		user_id = user_id ?? getToken()?.user_id

		if (!user_id) {
			return null
		}

		return handleUserProfile(`/v4/flw_user/${user_id}`)
	}

	async function userProfile(user_id?: string) {
		user_id = user_id ?? getToken()?.user_id

		if (!user_id) {
			return null
		}

		return handleUserProfile(`/v2/ce_user/${user_id}`)
	}

	async function userTokenRefresh(): Promise<any> {
		return Api.post(`/api/token/`)
			.then((response) => {
				return {
					...response.data,
					message: `User token refreshed`,
				}
			})
			.catch((error: AxiosError<ServerError>) => {
				return errorHandler(error)
			})
	}

	async function userLogout(): Promise<boolean> {
		try {
			const storedData = localStorage.getItem(TOKEN_TAG)
			if (!storedData) return

			const { refresh } = JSON.parse(storedData)

			if (refresh) {
				await Api.post('/api/logout/', { refresh_token: refresh }).catch((error: AxiosError<ServerError>) => {
					return errorHandler(error)
				})
			}
		} catch (error) {
			console.error('Logout error:', error)
		} finally {
			removeCurrentUser()
			resetRegisterStoreData()
			localStorage.removeItem(TOKEN_TAG)
			localStorage.clear()
			await sleep(500)
		}
	}

	const handleUserRecoverPassword = async (path: string, payload: any) => {
		return Api.post(path, payload)
			.then((response) => {
				return {
					...response.data,
					message: `User recovered successfully`,
				}
			})
			.catch((error: AxiosError<ServerError>) => {
				return errorHandler(error)
			})
	}

	async function userRecoverPassword(payload: UserLoginType) {
		return handleUserRecoverPassword(`/v1/password_reset/`, payload)
	}

	async function userFellowRecoverPassword(payload: UserLoginType) {
		return handleUserRecoverPassword(`/v4/password_reset/`, payload)
	}

	const handleUserOtpValidate = async (path: string, payload: any) => {
		return Api.post(path, payload)
			.then((response) => {
				return {
					...response.data,
					message: `Account validated successfully`,
				}
			})
			.catch((error: AxiosError<ServerError>) => {
				return errorHandler(error)
			})
	}

	async function userOtpValidate(payload: UserValidateType): Promise<any> {
		return handleUserOtpValidate(`/v2/ce_user/otp_validate/`, payload)
	}

	async function userFellowshipOtpValidate(payload: UserValidateType): Promise<any> {
		return handleUserOtpValidate(`/v4/flw_user/otp_validate/`, payload)
	}

	const handleUserOtpRequest = async (path: string, payload: any) => {
		return Api.post(path, payload)
			.then((response) => {
				return {
					...response.data,
					message: `We have sent a new OTP`,
				}
			})
			.catch((error: AxiosError<ServerError>) => {
				return errorHandler(error)
			})
	}

	async function userOtpRequest(payload: { email: string; reason: string } | { contact_number: string; reason: string }) {
		return handleUserOtpRequest(`/v2/ce_user_create/otp_request/`, payload)
	}

	async function userFellowshipOtpRequest(payload: { email: string; reason: string } | { contact_number: string; reason: string }) {
		return handleUserOtpRequest(`/v4/flw_user_create/otp_request/`, payload)
	}

	const handleUserUpdateProfile = async (path: string, payload: any, callback: Function) => {
		return Api.patch(path, payload)
			.then(async (response) => {
				try {
					await callback()
				} catch (error) {
					// @ts-ignore
				}

				return {
					...response.data,
					message: `Profile updated successfully`,
				}
			})
			.catch((error: AxiosError<ServerError>) => {
				return errorHandler(error)
			})
	}

	async function userUpdateProfile(payload: User, user_id?: string) {
		if (!user_id) {
			return null
		}

		return handleUserUpdateProfile(`/v2/ce_user/${user_id}/`, payload, userProfile)
	}

	async function userUpdateFellowshipProfile(payload: FlwUserPreRegistrationType & UserFlwRegistrationType, user_id?: string) {
		if (!user_id) {
			return null
		}

		return handleUserUpdateProfile(`/v4/flw_user/${user_id}/`, payload, userFlwProfile)
	}

	async function userDestroy() {
		return Api.delete(`/v1/user/remove/`)
			.then(async (response) => {
				return {
					...response.data,
					message: `Profile successfully removed`,
				}
			})
			.catch((error: AxiosError<ServerError>) => {
				return errorHandler(error)
			})
	}

	const handleUserResetPassword = async (path: string, payload: any) => {
		return Api.post(path, payload)
			.then((response) => {
				return {
					...response.data,
					message: `Reset email sent successfully`,
				}
			})
			.catch((error: AxiosError<ServerError>) => {
				return errorHandler(error)
			})
	}

	async function userResetPassword(email: string) {
		return handleUserResetPassword(`/v2/password_reset/`, { email })
	}

	async function userFellowResetPassword(email: string) {
		return handleUserResetPassword(`/v4/password_reset/`, { email })
	}

	async function updateUserType(user_type: string) {
		return Api.post(`/v2/ce_user/update_user_type/`, { user_type })
			.then((response) => {
				return {
					...response.data,
					message: `User type updated successfully`,
				}
			})
			.catch((error: AxiosError<ServerError>) => {
				return errorHandler(error)
			})
	}

	const handleUseretResetPassword = async (path: string, payload: any) => {
		return Api.post(path, payload)
			.then((response) => {
				return {
					...response.data,
					message: `Password reset successful`,
				}
			})
			.catch((error: AxiosError<ServerError>) => {
				return errorHandler(error)
			})
	}

	async function userSetResetPassword(password: string, token: string) {
		return handleUseretResetPassword(`/v2/password_reset/confirm/`, { password, token })
	}

	async function userFellowSetResetPassword(password: string, token: string) {
		return handleUseretResetPassword(`/v4/password_reset/confirm/`, { password, token })
	}

	async function userVerifyFellowshipInvite(token: string) {
		return Api.post(`/v4/fellowship_invite/verify-invite/`, {
			invite_token: token,
		})
			.then((response) => {
				return {
					...response.data,
					message: `Fellowship verification successful`,
				}
			})
			.catch((error: AxiosError<ServerError>) => {
				return {
					error: errorHandler(error),
				}
			})
	}

	return {
		userProfile,
		userFlwProfile,
		userLogout,
		userCreate,
		userFellowCreate,
		userCreateInformation,
		userFellowshipCreateInformation,
		userCreateConfirm,
		userLogin,
		userFlwLogin,
		userTokenRefresh,
		userRecoverPassword,
		userOtpValidate,
		userFellowshipOtpValidate,
		userOtpRequest,
		userFellowshipOtpRequest,
		userUpdateProfile,
		userUpdateFellowshipProfile,
		userDestroy,
		userResetPassword,
		updateUserType,
		userSetResetPassword,
		userVerifyFellowshipInvite,
		userFellowRecoverPassword,
		userFellowResetPassword,
		userFellowSetResetPassword,
	}
}
