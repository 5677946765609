import { Grid } from '@mui/material'
import { Formik, FormikContextType } from 'formik'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { sizing } from '../../../../constants'
import { Button } from '../../../button/button'
import { Form } from '../../../form-controlled-field/form'
import { Password } from '../../../form-controlled-field/password'
import { FormTitle } from '../../../form-controlled-field/title'
import { Spacer } from '../../../layout/spacer'
import { useProfileUpdate } from '../useProfileUpdate'
import { schema } from './src/schema'

interface FormUpdatePasswordProps {
	fellowship?: boolean
}

export const FormUpdatePassword: React.FC<FormUpdatePasswordProps> = ({ fellowship = false }) => {
	const navigate = useNavigate()
	const { handleSubmit } = useProfileUpdate({ fellowship })

	const hideValuesOnInput = ({ setStatus }: FormikContextType<any>) => {
		setStatus(null)
	}

	return (
		<React.Fragment>
			<Spacer height={60} />
			<Formik
				enableReinitialize={true}
				initialValues={{
					previous_password: ``,
					password: ``,
					password_confirm: ``,
				}}
				validationSchema={schema}
				validateOnChange={false}
				onSubmit={handleSubmit}>
				{({ status, isSubmitting }) => {
					return (
						<React.Fragment>
							<FormTitle
								title={`Update your password`}
								description={`Here you can update and verify your new password`}
								status={status}
							/>
							<Form onValuesChange={hideValuesOnInput}>
								<Password name={`previous_password`} label={`Current Password`} showStrength={false} />
								<Password name={`password`} label={`New Password`} />
								<Password name={`password_confirm`} label={`Confirm New Password`} showStrength={false} />
								<Spacer height={sizing.themeSpacing * 3} />
								<Grid container={true} spacing={1}>
									<Grid item={true} xs={12}>
										<Button
											type={`submit`}
											isLoading={isSubmitting}
											variant={`contained`}
											color={`secondary`}
											onClick={() => {}}>
											Update password
										</Button>
									</Grid>
									<Grid item={true} xs={12}>
										<Button type={`button`} variant={`text`} color={`secondary`} onClick={() => navigate(-1)}>
											Cancel
										</Button>
									</Grid>
								</Grid>
							</Form>
						</React.Fragment>
					)
				}}
			</Formik>
			<Spacer height={200} />
		</React.Fragment>
	)
}
