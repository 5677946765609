import { Container } from '@mui/material'
import { theme } from 'assets/material/theme'
import { Spacer } from 'components/layout/spacer'
import { Support } from 'components/support'

export default function PageFaqs() {
	return (
		<Container>
			<Spacer height={theme.spacing(3)} />
			<Support type='cfe' alwaysExpanded backgroundColor='transparent' />
			<Spacer height={theme.spacing(4)} />
		</Container>
	)
}
