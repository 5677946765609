import { pick } from 'lodash'
import { create } from 'zustand'
import { flw_user, user } from '../constants/user'
import { FlwUser, User, UserFlwRegistrationType, UserRegistrationType } from '../types/user'

export interface FormStore {
	formData: UserRegistrationType
	setStoreData: (_data: Partial<User>) => void
	resetStoreData: () => void
}

export const initialState: FormStore = {
	formData: pick(user, [
		'country_citizenship',
		'country',
		'dob',
		'gender',
		'address',
		'location',
		'municipality',
		'town',
		'state',
		'id_number',
		'sa_citizen',
		'income_over_250k',
		'race',
		'consent_given',
		'terms_accepted',
		'username',
		'reference_source',
		'reference_other',
		'user_type',
		'degree_name',
		'confirmed',
	]),
	setStoreData: () => {},
	resetStoreData: () => {},
}

export interface FlwFormStore {
	flwFormData: UserFlwRegistrationType
	setStoreData: (_data: Partial<FlwUser>) => void
	resetStoreData: () => void
}

export const initialFlwState: FlwFormStore = {
	flwFormData: pick(flw_user, [
		'dob',
		'country_citizenship',
		'country',
		'town',
		'state',
		'race',
		'consent_given',
		'terms_accepted',
		'gender',
		'username',
		'confirmed',
		'sa_citizen',
		'degree_name',
		'university',
		'degree_field',
		'academic_year',
	]),
	setStoreData: () => {},
	resetStoreData: () => {},
}

export const userRegisterInfoStore = create<FormStore>()((set) => ({
	...initialState,
	setStoreData: (data: any) =>
		set((state: Pick<FormStore, 'formData'>) => ({
			formData: { ...state.formData, ...data },
		})),
	resetStoreData: () => set(initialState),
}))

export const flwUserRegisterInfoStore = create<FlwFormStore>()((set) => ({
	...initialFlwState,
	setStoreData: (data: any) =>
		set((state: Pick<FlwFormStore, 'flwFormData'>) => ({
			flwFormData: { ...state.flwFormData, ...data },
		})),
	resetStoreData: () => set(initialFlwState),
}))
