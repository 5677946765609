import { Container } from '@mui/material'
import { Box } from 'components/box/box'
import { FormResetPassword } from 'components/form-controlled/reset-password/reset-password/form'
import { RegistrationFooter } from 'pages/auth/components/register-footer'

export default function PageFellowshipAccountResetPassword() {
	return (
		<Box display='flex' flexDirection='column' minHeight='calc(100% - 4.25em)'>
			<Box flexGrow={1}>
				<Container maxWidth={`xs`}>
					<FormResetPassword fellowship={true} />
				</Container>
			</Box>
			<RegistrationFooter />
		</Box>
	)
}
