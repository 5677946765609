import { Container } from '@mui/material'
import { FormAuthValidate } from 'components/form-controlled/validate/validate'

export default function PageFellowshipLoginValidate() {
	return (
		<Container maxWidth={`xs`}>
			<FormAuthValidate fellowship={true} />
		</Container>
	)
}
