import React from 'react'

import clsx from 'clsx'
import { ReactComponent as IconAdd } from '../../../assets/icon/icon-add.svg'
import { ReactComponent as IconAdminResources } from '../../../assets/icon/icon-admin-resources.svg'
import { ReactComponent as IconAlertError } from '../../../assets/icon/icon-alert-error.svg'
import { ReactComponent as IconAlertInfo } from '../../../assets/icon/icon-alert-info.svg'
import { ReactComponent as IconAlertSuccess } from '../../../assets/icon/icon-alert-success.svg'
import { ReactComponent as IconAlertWarning } from '../../../assets/icon/icon-alert-warning.svg'
import { ReactComponent as IconAppApple } from '../../../assets/icon/icon-app-apple.svg'
import { ReactComponent as IconAppGoogle } from '../../../assets/icon/icon-app-google.svg'
import { ReactComponent as IconAppHuawei } from '../../../assets/icon/icon-app-huawei.svg'
import { ReactComponent as IconArrowLeft } from '../../../assets/icon/icon-arrow-left.svg'
import { ReactComponent as IconBookLight } from '../../../assets/icon/icon-book-light.svg'
import { ReactComponent as IconBuilder } from '../../../assets/icon/icon-builder.svg'
import { ReactComponent as IconCalendar } from '../../../assets/icon/icon-calendar.svg'
import { ReactComponent as IconCaretDown } from '../../../assets/icon/icon-caret-down.svg'
import { ReactComponent as IconCaretLeft } from '../../../assets/icon/icon-caret-left.svg'
import { ReactComponent as IconCaretRight } from '../../../assets/icon/icon-caret-right.svg'
import { ReactComponent as IconCaretUp } from '../../../assets/icon/icon-caret-up.svg'
import { ReactComponent as IconCircleTick } from '../../../assets/icon/icon-circle-tick.svg'
import { ReactComponent as IconCircledArrowRight } from '../../../assets/icon/icon-circled-arrow-right.svg'
import { ReactComponent as IconClose } from '../../../assets/icon/icon-close.svg'
import { ReactComponent as IconConsumerEducation } from '../../../assets/icon/icon-consumer-education.svg'
import { ReactComponent as IconContactCard } from '../../../assets/icon/icon-contact-card.svg'
import { ReactComponent as IconContact } from '../../../assets/icon/icon-contact.svg'
import { ReactComponent as IconDownload } from '../../../assets/icon/icon-download.svg'
import { ReactComponent as IconFaqs } from '../../../assets/icon/icon-faqs.svg'
import { ReactComponent as IconFellowship } from '../../../assets/icon/icon-fellowship.svg'
import { ReactComponent as IconFormEdit } from '../../../assets/icon/icon-form-edit.svg'
import { ReactComponent as IconGoals } from '../../../assets/icon/icon-goals.svg'
import { ReactComponent as IconHamburgerMenu } from '../../../assets/icon/icon-hamburger-menu.svg'
import { ReactComponent as IconHome } from '../../../assets/icon/icon-home.svg'
import { ReactComponent as IconHourglass } from '../../../assets/icon/icon-hourglass.svg'
import { ReactComponent as IconLock } from '../../../assets/icon/icon-lock.svg'
import { ReactComponent as IconLogin } from '../../../assets/icon/icon-login.svg'
import { ReactComponent as IconLogoFilledSecondary } from '../../../assets/icon/icon-logo-filled-secondary.svg'
import { ReactComponent as IconLogoFilled } from '../../../assets/icon/icon-logo-filled.svg'
import { ReactComponent as IconLogo } from '../../../assets/icon/icon-logo.svg'
import { ReactComponent as IconLogout } from '../../../assets/icon/icon-logout.svg'
import { ReactComponent as IconMembers } from '../../../assets/icon/icon-members.svg'
import { ReactComponent as IconPaperCircled } from '../../../assets/icon/icon-paper-circled.svg'
import { ReactComponent as IconPaper } from '../../../assets/icon/icon-paper.svg'
import { ReactComponent as IconProfile } from '../../../assets/icon/icon-profile.svg'
import { ReactComponent as IconQAndA } from '../../../assets/icon/icon-q-and-a.svg'
import { ReactComponent as IconReadyToWork } from '../../../assets/icon/icon-readytowork.svg'
import { ReactComponent as IconReports } from '../../../assets/icon/icon-reports.svg'
import { ReactComponent as IconResources } from '../../../assets/icon/icon-resources.svg'
import { ReactComponent as IconSettings } from '../../../assets/icon/icon-settings.svg'
import { ReactComponent as IconSubjectMatter } from '../../../assets/icon/icon-subject-matter.svg'
import { ReactComponent as IconTimeline } from '../../../assets/icon/icon-timeline.svg'
import { ReactComponent as IconView } from '../../../assets/icon/icon-view.svg'
import { IconName } from '../../../types/common'

export const iconMap = {
	add: IconAdd,
	adminresources: IconAdminResources,
	arrowleft: IconArrowLeft,
	logo: IconLogo,
	logoFilled: IconLogoFilled,
	logoFilledSecondary: IconLogoFilledSecondary,
	apple: IconAppApple,
	bookLight: IconBookLight,
	builder: IconBuilder,
	google: IconAppGoogle,
	huawei: IconAppHuawei,
	calendar: IconCalendar,
	caretDown: IconCaretDown,
	caretUp: IconCaretUp,
	caretRight: IconCaretRight,
	caretLeft: IconCaretLeft,
	close: IconClose,
	download: IconDownload,
	consumerEducation: IconConsumerEducation,
	contact: IconContact,
	contactCard: IconContactCard,
	faqs: IconFaqs,
	fellowship: IconFellowship,
	formEdit: IconFormEdit,
	goals: IconGoals,
	hamburger: IconHamburgerMenu,
	home: IconHome,
	hourglass: IconHourglass,
	lock: IconLock,
	login: IconLogin,
	logout: IconLogout,
	members: IconMembers,
	profile: IconProfile,
	readyToWork: IconReadyToWork,
	reports: IconReports,
	resources: IconResources,
	settings: IconSettings,
	subject: IconSubjectMatter,
	timeline: IconTimeline,
	view: IconView,
	danger: IconAlertError,
	error: IconAlertError,
	success: IconAlertSuccess,
	warning: IconAlertWarning,
	info: IconAlertInfo,
	qAndA: IconQAndA,
	paper: IconPaper,
	paperCircled: IconPaperCircled,
	circledArrowRight: IconCircledArrowRight,
	circleTick: IconCircleTick,
}

export interface IconProps {
	name: IconName
	width?: string | number
	height?: string | number
	color?: string
	className?: string
}

const Component: React.FC<IconProps> = ({ name, width, height, color, className }) => {
	const Icon = iconMap[name]
	return Icon ? <Icon width={width} height={height} fill={color} color={color} className={clsx(`-cdp-ce-icon`, className)} /> : null
}

export default Component
