import { Hidden, Typography } from '@mui/material'
import * as React from 'react'
import { NavLink } from 'react-router-dom'
import { colors, sizing } from '../../../constants'
import { styles } from '../../../helpers/styles'
import { NavigationLinkDevicer } from '../../../types/common'
import { Box } from '../../box/box'
import { Row } from '../../grid'
import { Icon } from '../../icon'

interface HeaderLinkProps {
	icon?: any
	label?: string
	path?: string
	active?: boolean
	divider?: NavigationLinkDevicer[]
}

export const HeaderLink: React.FC<HeaderLinkProps> = ({ icon, label, path, active, divider = [] }) => {
	return (
		<NavLink
			to={path}
			target={path?.includes(`http`) ? `_blank` : `_self`}
			rel={`noreferrer`}
			className={({ isActive }) => (isActive ? 'header-menu-item active' : 'header-menu-item')}>
			<Row
				sx={{
					...styles.header.tab,
					paddingX: `${sizing.themeSpacing * 1.5}px`,
					paddingY: 0,
					borderBottom: active ? `3px solid ${colors.brand_care} !important` : `none`,
					color: active ? colors.brand_care : colors.text,
				}}>
				{divider?.includes(`left`) && (
					<Hidden implementation={`css`} mdDown>
						<Box height={40} width={1} borderLeft={`1px solid ${colors.muted}`} marginRight={`${sizing.themeSpacing}px`} />
					</Hidden>
				)}
				{icon && <Icon name={icon} width={`20px`} height={`20px`} color={active ? colors.brand_care : colors.text} />}
				<Typography
					marginLeft={`${sizing.themeSpacing}px`}
					fontWeight={active ? `bolder` : `normal`}
					color={active ? colors.primary : colors.text}>
					{label}
				</Typography>
			</Row>
		</NavLink>
	)
}
