import { Container } from '@mui/material'
import { Breadcrumbs } from 'components/breadcrumbs'
import { MiniDrawer } from 'components/mini-drawer'
import { useAppContext } from 'context/Provider'
import { useRoutes } from 'hooks/useRoutes'
import { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'

const PageAdminProgramDetail: React.FC = () => {
	const { activeBreadcrumb, setActiveBreadcrumb } = useAppContext()
	const { pathname } = useRoutes()
	const navigate = useNavigate()

	useEffect(() => {
		setActiveBreadcrumb({
			link: '',
			title: '2025 Absa Fellowship Programme Year 5 - 2025',
			subtitle: 'Absa Fellowship Year 5 (Onboarded 2021) 2025',
			status: 'Draft',
			buttons: [],
		})

		console.log(pathname.split('/'))

		if (pathname.split('/').length < 5) {
			navigate('settings')
		}
	}, [])

	return (
		<Container maxWidth={false} className='program-detail-admin'>
			{activeBreadcrumb && <Breadcrumbs />}
			<MiniDrawer />
			<Outlet />
		</Container>
	)
}

export default PageAdminProgramDetail
