import { FormikHelpers } from 'formik'
import { useAuthService } from 'services/user'

interface UseForgotPasswordProps {
	fellowship?: boolean
	validate?: boolean
}

export const useResetPassword = (props?: UseForgotPasswordProps) => {
	const { userResetPassword, userFellowResetPassword } = useAuthService()

	async function handleSubmit(values: any, { setFieldValue, setStatus }: FormikHelpers<any>) {
		setStatus({})
		const _func = props?.fellowship ? userFellowResetPassword : userResetPassword
		await _func(values['email'])
		setFieldValue('submitted', true)
	}

	return {
		handleSubmit,
	}
}
