import { Grid } from '@mui/material'
import { Formik } from 'formik'
import { pick } from 'lodash'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { sizing } from '../../../../constants'
import { gender, income, race, southAfricanStates } from '../../../../constants/options'
import { userCurrentStore } from '../../../../store/userCurrentStore'
import { AddressInterface } from '../../../../types/common'
// import { Button } from '../../../button/button'
import { Button } from 'components/button/button'
import { AcademicYear } from 'components/form-controlled-field/academic_year'
import { FieldOfStudy } from 'components/form-controlled-field/field_of_study'
import { University } from 'components/form-controlled-field/university'
import { useState } from 'react'
import { Country } from '../../../form-controlled-field/country'
import { Form } from '../../../form-controlled-field/form'
import { Radio } from '../../../form-controlled-field/radio'
import { Select } from '../../../form-controlled-field/select'
import { TextField } from '../../../form-controlled-field/text'
import { FormTitle } from '../../../form-controlled-field/title'
import { Spacer } from '../../../layout/spacer'
import { Places } from '../../../places'
import { useProfileUpdate } from '../useProfileUpdate'
import { fellowshipSchema, schema } from './src/schema'

interface FormUpdateProfileProps {
	fellowship?: boolean
}

const userFields = [
	`first_name`,
	`last_name`,
	`dob`,
	`gender`,
	`country`,
	`country_citizenship`,
	`id_number`,
	`gender`,
	`income_over_250k`,
	`address`,
	`municipality`,
	`town`,
	`state`,
	`race`,
]

const userFellowFields = [
	`first_name`,
	`last_name`,
	'dob',
	'gender',
	'country',
	'country_citizenship',
	'university',
	'degree_field',
	'degree_name',
	'academic_year',
	'race',
]

export const FormUpdateProfile: React.FC<FormUpdateProfileProps> = ({ fellowship = false }) => {
	const { currentUser } = userCurrentStore()
	const [initialFields] = useState<any>(fellowship ? userFellowFields : userFields)
	const [initialValues] = useState<any>(pick(currentUser, initialFields))
	const navigate = useNavigate()
	const { handleSubmit, handleCountryChange } = useProfileUpdate({ fellowship })

	return (
		<React.Fragment>
			<Spacer height={60} />
			<Formik
				enableReinitialize={true}
				initialValues={initialValues}
				validationSchema={fellowship ? fellowshipSchema : schema}
				onSubmit={handleSubmit}>
				{({ status, values, isSubmitting, setValues }) => {
					const southAfricanResident: boolean = values?.country === `South Africa`
					const southAfrican: boolean = values.country_citizenship === `South Africa`

					return (
						<React.Fragment>
							<FormTitle
								title={`Update your profile`}
								description={`Here you can update and verify your profile information`}
								status={status}
							/>

							<Form>
								<TextField name={`first_name`} label={`First Name`} placeholder={`e.g Johnathan`} disabled />
								<TextField name={`last_name`} label={`Last Name`} placeholder={`e.g Smith`} />
								<Country
									name={`country`}
									label={`Country of residence`}
									placeholder={`Select country`}
									afterChange={handleCountryChange}
								/>
								<Country name={`country_citizenship`} label={`Citizenship`} placeholder={`Select country`} disabled />
								<Radio name={`gender`} label={`Gender`} options={gender} disabled />
								{southAfrican && <Radio name={`race`} label={`Ethnicity`} options={race} disabled />}
								{fellowship && (
									<>
										<University
											name={`university`}
											label={`Which university do you study at?`}
											placeholder={`Select a university`}
										/>
										<FieldOfStudy
											name={`degree_field`}
											label={`In which field do you study currently?`}
											placeholder={`Select your field of study`}
										/>
										<Spacer height={8} />
										<TextField
											label={`What is the name of the degree you are studying?`}
											name={`degree_name`}
											placeholder={`Type the degree name`}
											plain={true}
										/>
										<AcademicYear
											name={`academic_year`}
											label={`What academic year are you in?`}
											placeholder={`Select academic year`}
										/>
									</>
								)}
								{!fellowship && (
									<>
										<Spacer height={8} />
										<Places
											label={`Residential address`}
											getAddress={({ address, town, stateName, municipality }: AddressInterface) => {
												setValues({
													...values,
													address: address,
													municipality: municipality,
													town: town,
													state: stateName,
												})
											}}
										/>
										<Spacer height={6} />
										<TextField name={`address`} placeholder={`Address`} plain={true} />
										<TextField name={`location`} placeholder={`Apartment (optional)`} plain={true} />
										<TextField name={`municipality`} placeholder={`Municipality`} plain={true} />
										<TextField name={`town`} placeholder={`Area or town`} plain={true} />
										{southAfricanResident ? (
											<Select
												name={`state`}
												placeholder={`Select your province`}
												options={southAfricanStates}
												plain={true}
											/>
										) : (
											<TextField name={`state`} placeholder={`State or Province`} plain={true} />
										)}
										{southAfrican && southAfricanResident && (
											<Radio name={`income_over_250k`} label={`How much do you earn per year?`} options={income} />
										)}
									</>
								)}
								<Spacer height={sizing.themeSpacing * 3} />
								<Grid container={true} spacing={1}>
									<Grid item={true} xs={12}>
										<Button type={`submit`} isLoading={isSubmitting} variant={`contained`} color={`secondary`}>
											Update
										</Button>
									</Grid>
									<Grid item={true} xs={12}>
										<Button type={`button`} variant={`text`} color={`secondary`} onClick={() => navigate(-1)}>
											Cancel
										</Button>
									</Grid>
								</Grid>
							</Form>
						</React.Fragment>
					)
				}}
			</Formik>
			<Spacer height={200} />
		</React.Fragment>
	)
}
