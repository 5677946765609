import { Grid } from '@mui/material'
import { Formik } from 'formik'
import { pick } from 'lodash'
import React, { useEffect } from 'react'
import { useGoogleService } from 'services/google'
import { getGooglePlace } from 'utils'
import { sizing } from '../../../../constants'
import { income, southAfricanStates } from '../../../../constants/options'
import { userCurrentStore } from '../../../../store/userCurrentStore'
import { AddressInterface } from '../../../../types/common'
import { Button } from '../../../button/button'
import { Country } from '../../../form-controlled-field/country'
import { Form } from '../../../form-controlled-field/form'
import { Radio } from '../../../form-controlled-field/radio'
import { Select } from '../../../form-controlled-field/select'
import { TextField } from '../../../form-controlled-field/text'
import { FormTitle } from '../../../form-controlled-field/title'
import { Spacer } from '../../../layout/spacer'
import { Places } from '../../../places'
import { useProfileUpdate } from '../useProfileUpdate'
import { schema } from './src/schema'

interface FormUpdateDetailsProps {
	fellowship?: boolean
}

export const FormUpdateDetails: React.FC<FormUpdateDetailsProps> = ({ fellowship = false }) => {
	const { handleUpdateSubmit, handleCountryChange } = useProfileUpdate()
	const { currentUser, setCurrentUser } = userCurrentStore()
	const { placesFind } = useGoogleService()

	const addressSearch = async () => {
		try {
			const response = await placesFind(currentUser?.address)
			if (response && response?.length) {
				return response[0]
			}
			return null
		} catch (error) {
			console.error(error)
			return null
		}
	}

	useEffect(() => {
		;(async () => {
			const address = await addressSearch()
			if (address) {
				const place = getGooglePlace(address)
				setCurrentUser({
					...currentUser,
					state: place.stateName,
					town: place.town,
					municipality: place.municipality,
				})
			}
		})()
	}, [])

	return (
		<React.Fragment>
			<Spacer height={60} />
			<Formik
				enableReinitialize={true}
				initialValues={pick(currentUser, [
					'country',
					'country_citizenship',
					'address',
					'municipality',
					'town',
					'state',
					'id_number',
					'income_over_250k',
					'dob',
					'user_type_raw',
				])}
				validationSchema={schema}
				onSubmit={handleUpdateSubmit}>
				{({ values, status, isSubmitting, setFieldValue }) => {
					const southAfricanResident: boolean = values.country === `South Africa`
					const southAfrican: boolean = values.country_citizenship === `South Africa`
					return (
						<React.Fragment>
							<FormTitle
								title={`Update your personal information`}
								description={`Once you have updated your personal information you will be able to access both ReadytoWork and Consumer Financial Education learning courses via the Absa Digital Hub using your existing ReadytoWork login credentials.`}
								status={status}
							/>
							<Form>
								<Country
									name={`country`}
									label={`Country of residence`}
									placeholder={`Select country`}
									afterChange={handleCountryChange}
								/>
								<Country name={`country_citizenship`} label={`Citizenship`} placeholder={`Select country`} />
								<Spacer height={sizing.themeSpacing * 3} />

								<Places
									label={`Residential address`}
									getAddress={({ address, town, stateName, municipality }: AddressInterface) => {
										setFieldValue(`address`, address)
										setFieldValue(`municipality`, municipality)
										setFieldValue(`town`, town)
										setFieldValue(`state`, stateName)
									}}
								/>
								<Spacer height={6} />

								<TextField name={`address`} placeholder={`Address`} plain={true} />
								<TextField name={`location`} placeholder={`Apartment (optional)`} plain={true} />
								<TextField name={`municipality`} placeholder={`Municipality`} plain={true} />
								<TextField name={`town`} placeholder={`Area or town`} plain={true} />
								{southAfricanResident ? (
									<Select name={`state`} placeholder={`Select your province`} options={southAfricanStates} plain={true} />
								) : (
									<TextField name={`state`} placeholder={`State or Province`} plain={true} />
								)}
								{southAfrican && (
									<TextField
										name={`id_number`}
										label={`What is your South African ID Number`}
										placeholder={`Enter your SA ID Number`}
									/>
								)}
								<Spacer height={6} />
								{southAfrican && southAfricanResident && (
									<Radio name={`income_over_250k`} label={`How much do you earn per year?`} options={income} />
								)}
								<Spacer height={sizing.themeSpacing * 3} />
								<Grid container={true} spacing={1}>
									<Grid item={true} xs={12}>
										<Button type={`submit`} isLoading={isSubmitting} variant={`contained`} color={`secondary`}>
											Confirm
										</Button>
									</Grid>
								</Grid>
							</Form>
						</React.Fragment>
					)
				}}
			</Formik>
			<Spacer height={200} />
		</React.Fragment>
	)
}
