import { Container } from '@mui/material'
import { FormConfirmDetails } from 'components/form-controlled/account/confirm/form'
import { RegistrationFooter } from 'pages/auth/components/register-footer'
import React from 'react'

export default function PageFellowshipLoginConfirmDetails() {
	return (
		<React.Fragment>
			<Container maxWidth={`xs`}>
				<FormConfirmDetails />
			</Container>
			<RegistrationFooter />
		</React.Fragment>
	)
}
