import { SetFieldValueForFormik, SetValuesForFormik } from 'components/form-controlled-field/country/src/Component'
import { FormikHelpers } from 'formik'
import { useNavigate } from 'react-router-dom'
import { useAppContext } from '../../../context/Provider'
import { useAuthService } from '../../../services/user'
import { userCurrentStore } from '../../../store/userCurrentStore'
import { sleep } from '../../../utils'

interface UseProfileUpdateProps {
	fellowship?: boolean
	validate?: boolean
}

export const useProfileUpdate = (props?: UseProfileUpdateProps) => {
	const navigate = useNavigate()
	const { setGlobalLoader, setSnackbar } = useAppContext()
	const { userUpdateProfile, userOtpRequest, userUpdateFellowshipProfile, userFellowshipOtpRequest } = useAuthService()
	const { currentUser } = userCurrentStore()
	const { email, contact_number, id } = currentUser || {}

	async function handleConfirmSubmit(values: any, params: FormikHelpers<any>) {
		return runSubmitHandler(props?.fellowship ? '/fellowship/account' : '/')(values, params, 'six_months')
	}

	async function handleUpdateSubmit(values: any, params: FormikHelpers<any>) {
		return runSubmitHandler(props?.fellowship ? '/fellowship/account' : '/')(values, params)
	}

	function handleSubmit(values: any, params: FormikHelpers<any>) {
		return runSubmitHandler(props?.fellowship ? '/fellowship/account' : '/')(values, params)
	}

	function runSubmitHandler(nextPath: string) {
		return function _baseHandleSubmit(values: any, { setStatus, setSubmitting }: FormikHelpers<any>, reason?: string) {
			if (reason !== 'six_months') reason = values?.email ? 'change_email' : 'change_mobile'

			const otpValidate =
				!values?.previous_password && (props?.validate || values?.email || values?.contact_number || values?.password_confirm)

			const userUpdateCallback = () => {
				const _func = props.fellowship ? userUpdateFellowshipProfile : userUpdateProfile
				return _func(
					{
						...values,
					},
					id
				).then(async (res: any) => {
					setGlobalLoader(false)
					setSubmitting(false)
					if (res?.error) {
						setStatus({
							error: res?.message,
						})
						setSnackbar({
							open: true,
							message: res?.message,
							severity: `error`,
						})
					} else {
						setStatus({
							success: res?.message,
						})
					}

					return res
				})
			}

			if (otpValidate) {
				setSnackbar({
					open: true,
					message: `Please validate your account`,
					severity: `success`,
				})
				const _func = props.fellowship ? userFellowshipOtpRequest : userOtpRequest
				_func({
					...(values?.email ? { contact_number, reason } : { email, reason }),
				})
				sleep(1000)

				navigate(props?.fellowship ? '/fellowship/account' : '/validate', {
					state: values?.email ? { contact_number, reason, values, id } : { email, reason, values, id },
				})
			} else {
				setSubmitting(true)
				setGlobalLoader(true)
				userUpdateCallback().then((res) => {
					if (!res?.error) {
						sleep(1000).then(() => navigate(nextPath))
					}
				})
			}
		}
	}

	async function handleCountryChange(
		name: string,
		value: any,
		setFieldValue: SetFieldValueForFormik,
		oldValues: any,
		setValues: SetValuesForFormik
	) {
		return setValues({
			...oldValues,
			[name]: value,
			address: '',
			location: '',
			municipality: '',
			town: '',
			state: '',
			income_over_250k: null,
		})
	}

	async function handleCitizenshipChange(
		name: string,
		value: any,
		setFieldValue: SetFieldValueForFormik,
		oldValues: any,
		setValues: SetValuesForFormik
	) {
		const fromValue = oldValues[name]

		if (fromValue === value) {
			return
		}

		await setFieldValue('race', null)
		// await setFieldValue('id_number', '')
		await setFieldValue('income_over_250k', null)
	}

	return {
		handleSubmit,
		handleConfirmSubmit,
		handleUpdateSubmit,
		handleCountryChange,
		handleCitizenshipChange,
	}
}
