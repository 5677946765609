import { Chip, Grid } from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Button } from 'components/button/button'
import { Icon } from 'components/icon'
import { Link } from 'components/link'
import { useAppContext } from 'context/Provider'
import * as React from 'react'
import { memo } from 'react'

const Component: React.FC = () => {
	const { activeBreadcrumb } = useAppContext()

	return (
		<Box className='admin-breadcrumbs'>
			<Box component='main'>
				<Grid container={true} spacing={2} className='main-grid'>
					<Grid item width={64}>
						<Link underline={false} bold={true} to={'/admin/programs'}>
							<Icon name={`arrowleft`} width={20} height={20} />
						</Link>
					</Grid>
					<Grid item xs={5} md={6} padding={0}>
						<Typography variant='h3'>
							{activeBreadcrumb?.title}
							<Chip label={activeBreadcrumb?.status} variant='outlined' color='warning' style={{ marginLeft: 20 }} />
						</Typography>
						<Typography variant='body1'>{activeBreadcrumb?.subtitle}</Typography>
					</Grid>

					<Grid item xs={5} md={5} container justifyContent='space-between'>
						<div>
							<Button variant={`text`} color={`secondary`} fullWidth={false} style={{ marginRight: 15 }}>
								<Icon name='view' width={20} /> <span style={{ marginLeft: 15 }}>Preview</span>
							</Button>
							<Button variant={`outlined`} color={`secondary`} fullWidth={false} style={{ marginRight: 15 }}>
								Publish
							</Button>
							<Button variant={`contained`} color={`secondary`} fullWidth={false}>
								Save
							</Button>
						</div>
					</Grid>
				</Grid>
			</Box>
		</Box>
	)
}

export default memo(Component)
