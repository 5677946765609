import { Grid } from '@mui/material'
import { Formik } from 'formik'
import { pick } from 'lodash'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { sizing } from '../../../../constants'
import { userCurrentStore } from '../../../../store/userCurrentStore'
import { Button } from '../../../button/button'
import { Form } from '../../../form-controlled-field/form'
import { Phone } from '../../../form-controlled-field/phone'
import { FormTitle } from '../../../form-controlled-field/title'
import { Spacer } from '../../../layout/spacer'
import { useProfileUpdate } from '../useProfileUpdate'
import { schema } from './src/schema'

interface FormUpdateContactNumberProps {
	fellowship?: boolean
}

export const FormUpdateContactNumber: React.FC<FormUpdateContactNumberProps> = ({ fellowship = false }) => {
	const navigate = useNavigate()
	const { handleSubmit } = useProfileUpdate({ fellowship })
	const { currentUser } = userCurrentStore()

	return (
		<React.Fragment>
			<Spacer height={60} />
			<Formik
				enableReinitialize={true}
				initialValues={pick(currentUser, ['contact_number'])}
				validationSchema={schema}
				onSubmit={handleSubmit}>
				{({ status, isSubmitting }) => {
					return (
						<React.Fragment>
							<FormTitle
								title={`Update your contact number`}
								description={`Here you can update and verify your contact number`}
								status={status}
							/>
							<Form>
								<Phone name={`contact_number`} label={`Contact number`} />
								<Spacer height={sizing.themeSpacing * 3} />
								<Grid container={true} spacing={1}>
									<Grid item={true} xs={12}>
										<Button
											type={`submit`}
											isLoading={isSubmitting}
											variant={`contained`}
											color={`secondary`}
											onClick={() => {}}>
											Confirm
										</Button>
									</Grid>
									<Grid item={true} xs={12}>
										<Button type={`button`} variant={`text`} color={`secondary`} onClick={() => navigate(-1)}>
											Cancel
										</Button>
									</Grid>
								</Grid>
							</Form>
						</React.Fragment>
					)
				}}
			</Formik>
			<Spacer height={200} />
		</React.Fragment>
	)
}
