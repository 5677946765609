import AdminRoutesAuthenticatedLayout from 'components/layout/admin-routes-authenticated-layout'
import AdminRoutesUnauthenticatedLayout from 'components/layout/admin-routes-unauthenticated-layout'
import PageAccountResetPassword from 'pages/account/reset-password'
import PageAccountSetResetPassword from 'pages/account/set-reset-password'
import PageAdminHome from 'pages/admin/admin-home'
import PageRegisterAdminCredentials from 'pages/admin/auth/register/credentials'
import PageAdminBranding from 'pages/admin/branding'
import PageAdminELearning from 'pages/admin/e-learning'
import PageAdminHelpCentre from 'pages/admin/help-centre'
import PageAdminPrograms from 'pages/admin/programs'
import PageAdminProgramDetail from 'pages/admin/programs/program-detail'
import PageAdminBuilder from 'pages/admin/programs/program-detail/builder'
import PageAdminGoals from 'pages/admin/programs/program-detail/goals'
import PageAdminMembers from 'pages/admin/programs/program-detail/members'
import PageAdminProgramResources from 'pages/admin/programs/program-detail/program-resources'
import PageAdminReports from 'pages/admin/programs/program-detail/reports'
import PageAdminSettings from 'pages/admin/programs/program-detail/settings'
import PageAdminTimeline from 'pages/admin/programs/program-detail/timeline'
import PageAdminResources from 'pages/admin/resources'
import PageAdminUsers from 'pages/admin/users'
import PageFellowshipAccountHome from 'pages/fellowship/account/fellowship-home'
import PageFellowshipAccountUpdateContactNumber from 'pages/fellowship/account/fellowship-update-contact-number'
import PageFellowshipAccountUpdateDetails from 'pages/fellowship/account/fellowship-update-details'
import PageFellowshipAccountUpdateEmail from 'pages/fellowship/account/fellowship-update-email'
import PageFellowshipAccountUpdatePassword from 'pages/fellowship/account/fellowship-update-password'
import PageFellowshipAccountUpdateProfile from 'pages/fellowship/account/fellowship-update-profile'
import PageFellowshipLoginConfirmDetails from 'pages/fellowship/auth/login/confirm-details'
import PageFellowshipLoginCredentials from 'pages/fellowship/auth/login/credentials'
import PageFellowshipLoginValidate from 'pages/fellowship/auth/login/validate'
import PageRegisterFellowshipCredentials from 'pages/fellowship/auth/register/credentials'
import PageRegisterFellowshipInformation from 'pages/fellowship/auth/register/information'
import PageFellowshipAccountResetPassword from 'pages/fellowship/auth/reset-password'
import PageFellowshipAccountSetResetPassword from 'pages/fellowship/auth/set-reset-password'
import PageFellowship from 'pages/fellowship/fellowship'
import PageHomeConsumerEducation from 'pages/home/consumer-financial-education'
import PageHomeReadyToWork from 'pages/home/ready-to-work'
import React, { memo, useEffect } from 'react'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import { useAuthService } from 'services/user'
import RoutesErrorBoundary from '../components/layout/routes-error-boundary'
import Routes from '../components/layout/routes-layout'
import { TOKEN_TAG } from '../constants'
import PageAccountHome from '../pages/account/home'
import PageAccountUpdateContactNumber from '../pages/account/update-contact-number'
import PageAccountUpdateDetails from '../pages/account/update-details'
import PageAccountUpdateEmail from '../pages/account/update-email'
import PageAccountUpdatePassword from '../pages/account/update-password'
import PageAccountUpdateProfile from '../pages/account/update-profile'
import PageLoginConfirmDetails from '../pages/auth/login/confirm-details'
import { default as PageLoginCredentials } from '../pages/auth/login/credentials'
import PageLoginValidate from '../pages/auth/login/validate'
import PageLogout from '../pages/auth/logout/logout'
import PageRegisterCredentials from '../pages/auth/register/credentials'
import PageRegisterInformation from '../pages/auth/register/information'
import PageValidate from '../pages/auth/validate/validate'
import PageFaqs from '../pages/faqs/faqs'
import PageHome from '../pages/home/home'
import PageNotFound from '../pages/not-found/404'
import PageResources from '../pages/resources/resources'
import { RouteProps } from '../types/common'
import {
	handleAdminIsAuthenticated,
	handleAdminProtected,
	handleFellowshipGuestOnly,
	handleFellowshipProtected,
	handleGuestOnly,
	handleProgramsRedirect,
	handleProtected,
} from '../utils'

export const routes: RouteProps[] = [
	{
		index: true,
		label: 'Dashboard',
		icon: 'home',
		element: <PageHome />,
		placement: ['aside'],
		display: ['public', 'private'],
	},
	{
		index: true,
		path: 'consumer-financial-education',
		label: 'Consumer Financial Education',
		icon: 'consumerEducation',
		element: <PageHomeConsumerEducation />,
		placement: ['aside', 'header-left'],
		display: ['public', 'private'],
		// children: [
		// 	{
		// 		index: true,
		// 		element: <PageHomeConsumerEducation />,
		// 	},
		// 	{
		// 		path: 'flc',
		// 		label: 'Financial Literacy Course',
		// 		icon: 'add',
		// 		// element: <ScormModuleViewPage />,
		// 		element: <ScormAssessment />,
		// 		loader: async () => await handleProtected(),
		// 	},
		// ],
	},
	{
		index: true,
		path: 'ready-to-work',
		label: 'ReadyToWork',
		icon: 'readyToWork',
		element: <PageHomeReadyToWork />,
		placement: ['aside', 'header-left'],
		display: ['public', 'private'],
	},
	{
		path: 'fellowship',
		label: 'Fellowship',
		placement: [],
		display: ['public', 'private'],
		children: [
			{
				index: true,
				element: <PageFellowship />,
			},
			{
				path: 'register',
				label: 'Create an account',
				icon: 'add',
				placement: ['aside'],
				loader: async () => await handleFellowshipGuestOnly(),
				display: ['public'],
				children: [
					{
						index: true,
						element: <PageRegisterFellowshipCredentials />,
					},
					{
						path: 'information',
						label: 'Provide your profile information',
						icon: 'add',
						element: <PageRegisterFellowshipInformation />,
					},
				] as RouteProps[],
			},
			{
				path: 'login',
				label: 'Login',
				icon: 'login',
				placement: ['aside', 'header-right'],
				loader: async () => await handleFellowshipGuestOnly(),
				display: ['public'],
				children: [
					{
						index: true,
						element: <PageFellowshipLoginCredentials />,
					},
					{
						path: 'validate',
						label: 'Validate login',
						icon: 'login',
						element: <PageFellowshipLoginValidate />,
					},
					{
						path: 'confirm-details',
						label: 'Confirm your details',
						icon: 'login',
						private: true,
						element: <PageFellowshipLoginConfirmDetails />,
					},
				] as RouteProps[],
			},
			{
				path: 'reset-password',
				label: 'Reset Password',
				icon: 'login',
				placement: ['header-right'],
				loader: async () => await handleFellowshipGuestOnly(),
				display: ['public'],
				children: [
					{
						index: true,
						element: <PageFellowshipAccountResetPassword />,
					},
					{
						path: 'set',
						label: 'New Password',
						icon: 'login',
						element: <PageFellowshipAccountSetResetPassword />,
					},
				] as RouteProps[],
			},
			{
				path: 'account',
				label: 'Absa Fellowship',
				loader: async () => await handleFellowshipProtected(),
				children: [
					{
						index: true,
						element: <PageFellowshipAccountHome />,
					},
					{
						path: 'update',
						label: 'Update your user account',
						icon: 'add',
						element: <PageFellowshipAccountUpdateProfile />,
					},
					{
						path: 'update-email',
						label: 'Update your email address',
						icon: 'add',
						element: <PageFellowshipAccountUpdateEmail />,
					},
					{
						path: 'update-contact-number',
						label: 'Update your contact number',
						icon: 'add',
						element: <PageFellowshipAccountUpdateContactNumber />,
					},
					{
						path: 'update-password',
						label: 'Update your password',
						icon: 'add',
						element: <PageFellowshipAccountUpdatePassword />,
					},
					{
						path: 'update-details',
						label: 'Update your details',
						icon: 'login',
						element: <PageFellowshipAccountUpdateDetails />,
					},
				] as RouteProps[],
			},
		],
	},
	{
		index: true,
		path: 'faqs',
		label: 'FAQs',
		icon: 'faqs',
		element: <PageFaqs />,
		placement: ['aside', 'header-left'],
		display: ['public', 'private'],
	},
	{
		index: true,
		path: 'resources',
		label: 'Resources',
		icon: 'resources',
		divider: [`bottom`],
		element: <PageResources />,
		placement: ['aside', 'header-left'],
		loader: async () => await handleProtected(),
		display: ['public', 'private'],
	},
	{
		path: 'account',
		label: 'Profile',
		icon: 'profile',
		placement: ['aside', 'header-right'],
		loader: async () => await handleProtected(),
		display: ['public'],
		children: [
			{
				index: true,
				element: <PageAccountHome />,
			},
			{
				path: 'update',
				label: 'Update your user account',
				icon: 'add',
				element: <PageAccountUpdateProfile />,
			},
			{
				path: 'update-email',
				label: 'Update your email address',
				icon: 'add',
				element: <PageAccountUpdateEmail />,
			},
			{
				path: 'update-contact-number',
				label: 'Update your contact number',
				icon: 'add',
				element: <PageAccountUpdateContactNumber />,
			},
			{
				path: 'update-password',
				label: 'Update your password',
				icon: 'add',
				element: <PageAccountUpdatePassword />,
			},
			{
				path: 'update-details',
				label: 'Update your details',
				icon: 'login',
				element: <PageAccountUpdateDetails />,
			},
		] as RouteProps[],
	},
	{
		path: 'login',
		label: 'Login',
		icon: 'login',
		placement: ['aside', 'header-right'],
		loader: async () => await handleGuestOnly(),
		display: ['public'],
		children: [
			{
				index: true,
				element: <PageLoginCredentials />,
			},
			{
				path: 'validate',
				label: 'Validate login',
				icon: 'login',
				element: <PageLoginValidate />,
			},
			{
				path: 'confirm-details',
				label: 'Confirm your details',
				icon: 'login',
				private: true,
				element: <PageLoginConfirmDetails />,
			},
		] as RouteProps[],
	},
	{
		path: 'reset-password',
		label: 'Reset Password',
		icon: 'login',
		placement: ['aside', 'header-right'],
		loader: async () => await handleGuestOnly(),
		display: ['public'],
		children: [
			{
				index: true,
				element: <PageAccountResetPassword />,
			},
			{
				path: 'set',
				label: 'New Password',
				icon: 'login',
				element: <PageAccountSetResetPassword />,
			},
			// {
			// 	path: 'validate',
			// 	label: 'Validate login',
			// 	icon: 'login',
			// 	element: <PageLoginValidate />,
			// },
			// {
			// 	path: 'confirm-details',
			// 	label: 'Confirm your details',
			// 	icon: 'login',
			// 	private: true,
			// 	element: <PageLoginConfirmDetails />,
			// },
		] as RouteProps[],
	},
	{
		path: 'register',
		label: 'Create an account',
		icon: 'add',
		placement: ['aside'],
		loader: async () => await handleGuestOnly(),
		display: ['public'],
		children: [
			{
				index: true,
				element: <PageRegisterCredentials />,
			},
			{
				path: 'information',
				label: 'Provide your profile information',
				icon: 'add',
				element: <PageRegisterInformation />,
			},
		] as RouteProps[],
	},
	{
		index: true,
		path: 'validate',
		label: 'Validate',
		icon: 'add',
		element: <PageValidate />,
	},
	{
		index: true,
		path: 'logout',
		label: 'Log out',
		icon: 'logout',
		placement: ['aside'],
		display: ['private'],
		element: <PageLogout />,
	},
	{
		path: '*',
		element: <PageNotFound />,
	},
] as RouteProps[]

export const admin_routes: RouteProps[] = [
	{
		index: true,
		label: 'Dashboard',
		icon: 'home',
		loader: async () => await handleAdminProtected(),
		element: <PageAdminHome />,
		placement: [],
		display: ['private'],
	},
	{
		index: true,
		path: 'programs',
		label: 'Programs',
		divider: [`bottom`],
		element: <PageAdminPrograms />,
		placement: ['header-left'],
		loader: async () => await handleAdminProtected(),
		display: ['private'],
	},
	{
		path: 'program/:id',
		label: 'Program',
		divider: [`bottom`],
		placement: ['aside'],
		loader: async () => await handleProtected(),
		display: ['public', 'private'],
		element: <PageAdminProgramDetail />,
		children: [
			{
				index: true,
				path: 'settings',
				label: 'Settings',
				icon: 'settings',
				element: <PageAdminSettings />,
			},
			{
				path: 'builder',
				label: 'Builder',
				icon: 'builder',
				element: <PageAdminBuilder />,
			},
			{
				path: 'timeline',
				label: 'Timeline',
				icon: 'timeline',
				element: <PageAdminTimeline />,
			},
			{
				path: 'members',
				label: 'Members',
				icon: 'members',
				element: <PageAdminMembers />,
			},
			{
				path: 'resources',
				label: 'Resources',
				icon: 'adminresources',
				element: <PageAdminProgramResources />,
			},
			{
				path: 'goals',
				label: 'Goals',
				icon: 'goals',
				element: <PageAdminGoals />,
			},
			{
				path: 'reports',
				label: 'Reports',
				icon: 'reports',
				element: <PageAdminReports />,
			},
			{
				path: '*',
				loader: async () => await handleProgramsRedirect(),
			},
		] as RouteProps[],
	},
	{
		index: true,
		path: 'users',
		label: 'Users',
		divider: [`bottom`],
		element: <PageAdminUsers />,
		placement: ['header-left'],
		loader: async () => await handleAdminProtected(),
		display: ['private'],
	},
	{
		index: true,
		path: 'resources',
		label: 'Resources',
		divider: [`bottom`],
		element: <PageAdminResources />,
		placement: ['header-left'],
		loader: async () => await handleAdminProtected(),
		display: ['private'],
	},
	{
		index: true,
		path: 'e-learning',
		label: 'E-Learnings',
		divider: [`bottom`],
		element: <PageAdminELearning />,
		placement: ['header-left'],
		loader: async () => await handleAdminProtected(),
		display: ['private'],
	},
	{
		index: true,
		path: 'help-centre',
		label: 'Help Centre',
		divider: [`bottom`],
		element: <PageAdminHelpCentre />,
		placement: ['header-left'],
		loader: async () => await handleAdminProtected(),
		display: ['private'],
	},
	{
		index: true,
		path: 'branding',
		label: 'Branding',
		divider: [`bottom`],
		element: <PageAdminBranding />,
		placement: ['header-left'],
		loader: async () => await handleAdminProtected(),
		display: ['private'],
	},
	{
		path: 'account',
		label: 'Profile',
		icon: 'profile',
		placement: ['header-right'],
		loader: async () => await handleAdminProtected(),
		display: ['private'],
		children: [
			{
				index: true,
				element: <PageAccountHome />,
			},
			{
				path: 'update',
				label: 'Update your user account',
				icon: 'add',
				element: <PageAccountUpdateProfile />,
			},
			{
				path: 'update-email',
				label: 'Update your email address',
				icon: 'add',
				element: <PageAccountUpdateEmail />,
			},
			{
				path: 'update-contact-number',
				label: 'Update your contact number',
				icon: 'add',
				element: <PageAccountUpdateContactNumber />,
			},
			{
				path: 'update-password',
				label: 'Update your password',
				icon: 'add',
				element: <PageAccountUpdatePassword />,
			},
			{
				path: 'update-details',
				label: 'Update your details',
				icon: 'login',
				element: <PageAccountUpdateDetails />,
			},
		] as RouteProps[],
	},
	{
		index: true,
		path: 'logout',
		label: 'Log out',
		icon: 'logout',
		placement: [],
		display: ['private'],
		element: <PageLogout />,
	},
	{
		path: '**',
		element: <PageNotFound />,
	},
] as RouteProps[]

export const admin_auth_routes: RouteProps[] = [
	{
		index: true,
		label: 'Admin ',
		icon: 'home',
		loader: async () => await handleAdminIsAuthenticated(),
		element: <PageRegisterAdminCredentials />,
		placement: [],
		display: ['public', 'private'],
	},
	{
		path: 'login',
		label: 'Login',
		icon: 'login',
		placement: ['header-right'],
		loader: async () => await handleAdminIsAuthenticated(),
		display: ['public'],
		children: [
			{
				index: true,
				element: <PageLoginCredentials />,
			},
			{
				path: 'validate',
				label: 'Validate login',
				icon: 'login',
				element: <PageLoginValidate />,
			},
			{
				path: 'confirm-details',
				label: 'Confirm your details',
				icon: 'login',
				private: true,
				element: <PageLoginConfirmDetails />,
			},
		] as RouteProps[],
	},
	{
		path: 'reset-password',
		label: 'Reset Password',
		icon: 'login',
		placement: ['header-right'],
		loader: async () => await handleAdminIsAuthenticated(),
		display: ['public'],
		children: [
			{
				index: true,
				element: <PageAccountResetPassword />,
			},
			{
				path: 'set',
				label: 'New Password',
				icon: 'login',
				element: <PageAccountSetResetPassword />,
			},
		] as RouteProps[],
	},
	{
		index: true,
		path: 'validate',
		label: 'Validate',
		icon: 'add',
		element: <PageValidate />,
	},
	{
		path: '*',
		element: <PageRegisterAdminCredentials />,
	},
] as RouteProps[]

export const layout: RouteProps[] = [
	{
		path: '/',
		element: <Routes />,
		errorElement: <RoutesErrorBoundary />,
		children: routes,
	},
	{
		path: '/admin/',
		element: <AdminRoutesAuthenticatedLayout />,
		errorElement: <RoutesErrorBoundary />,
		children: admin_routes,
	},
	{
		path: '/admin-auth/',
		element: <AdminRoutesUnauthenticatedLayout />,
		errorElement: <RoutesErrorBoundary />,
		children: admin_auth_routes,
	},
]

const router = createBrowserRouter(layout, {
	future: {
		v7_normalizeFormMethod: true,
	},
})

export const AppNavigator: React.FC = memo(() => {
	const { userLogout } = useAuthService()
	const [checkSession, setCheckSession] = React.useState<number>()

	useEffect(() => {
		const sessionCheckerFn: number = window.setInterval(() => {
			const tokenStr = window.localStorage.getItem(TOKEN_TAG)
			if (tokenStr) {
				const token = JSON.parse(tokenStr)
				const createdAt = new Date(Date.parse(token['created_at']))
				const now = new Date()
				const deltaTime = now.getTime() - createdAt.getTime()
				const expireAfterSeconds = 60 * 59 * 2 * 1000
				if (deltaTime > expireAfterSeconds) {
					userLogout().then(() => {
						window.location.pathname = '/login'
					})
				}
			}
		}, 1000)
		setCheckSession(sessionCheckerFn)

		return () => {
			clearInterval(checkSession)
		}
	}, [])

	return <RouterProvider router={router} />
})
