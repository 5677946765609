import { ThemeProvider } from '@mui/material'
import { theme } from 'assets/material/theme'
import { Provider } from 'context/Provider'
import { Outlet } from 'react-router-dom'
import AdminLayout from './admin-layout'

function AdminRoutesAuthenticatedLayout() {
	return (
		<ThemeProvider theme={theme}>
			<Provider>
				<AdminLayout>
					<Outlet />
				</AdminLayout>
			</Provider>
		</ThemeProvider>
	)
}

export default AdminRoutesAuthenticatedLayout
