import { Container } from '@mui/material'
import { FormAuthRegister } from 'components/form-controlled/register/register/form'
import { RegistrationFooter } from 'pages/auth/components/register-footer'
import React from 'react'

export default function PageRegisterFellowshipCredentials() {
	return (
		<React.Fragment>
			<Container maxWidth={`xs`}>
				<FormAuthRegister fellowship={true} />
			</Container>
			<RegistrationFooter />
		</React.Fragment>
	)
}
