import { Alert, AlertColor, Snackbar as MuiSnackbar, SnackbarOrigin, useMediaQuery } from '@mui/material'
import { Splash } from 'components/splash'
import { Dispatch, FC, ReactElement, ReactNode, createContext, memo, useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuthService } from 'services/user'
import { getToken, sleep } from 'utils'
import { AlertDialog } from '../components/alert'
import { alertIconMapping } from '../components/alert/src/Component'
import { DialogInterface } from '../components/alert/src/Component.dialog'
import { GlobalProgress } from '../components/progress'
import { __DEV__, colors } from '../constants'

interface SnackbarInterface extends SnackbarOrigin {
	open: boolean
	message: string
	severity: AlertColor
}

interface ProviderContextType {
	setGlobalLoader: Dispatch<React.SetStateAction<boolean>>
	setSnackbar: any
	setDialog: (_args: DialogInterface) => void
	activeBreadcrumb: BreadcrumbInterface | null
	setActiveBreadcrumb: Dispatch<React.SetStateAction<BreadcrumbInterface | null>>
}

export const AppContext = createContext<Partial<ProviderContextType>>({
	setGlobalLoader: undefined,
	setSnackbar: undefined,
	setDialog: undefined,
	activeBreadcrumb: undefined,
	setActiveBreadcrumb: undefined,
})

export const initialDialogue: DialogInterface = {
	open: false,
	title: '',
	description: 'center',
	onConfirm: () => {},
	setDialog: () => {},
	confirmTitle: `info`,
	variant: `info`,
}

export const initialSnackbar: SnackbarInterface = {
	open: false,
	vertical: 'bottom',
	horizontal: 'left',
	message: '',
	severity: `info`,
}

interface BreadcrumbInterface {
	link?: string
	title?: string
	subtitle?: string
	status?: string
	buttons?: ReactElement[] | ReactNode[] | JSX.Element[] | JSX.Element
}

export const initialBreadcrumb: BreadcrumbInterface = {
	link: '',
	title: '',
	subtitle: '',
	status: '',
	buttons: [],
}

export const useAppContext = () => useContext(AppContext)

const Provider: FC<any> = memo(function ProviderCommon({ children }) {
	const navigate = useNavigate()
	const { userLogout } = useAuthService()
	const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('md')) ?? true
	const [preLoader, setPreLoader] = useState<boolean>(true)
	const [globalLoader, setGlobalLoader] = useState<boolean>(false)
	const [dialog, setDialog] = useState<DialogInterface>(initialDialogue)
	const [snackbar, setSnackbar] = useState<SnackbarInterface>(initialSnackbar)
	const [activeBreadcrumb, setActiveBreadcrumb] = useState<BreadcrumbInterface | null>({
		link: '/admin/programs',
		title: '2025 Absa Fellowship Programme Year 5 - 2025',
		subtitle: 'Absa Fellowship Year 5 (Onboarded 2021) 2025',
		status: 'Draft',
		buttons: [],
	})

	const snackbarHandleClose = () => {
		setSnackbar({ ...snackbar, open: false })
	}

	useEffect(() => {
		;(async () => {
			const token = getToken()
			if (token?.access && !token?.remember_me) {
				await userLogout()
				navigate(`/login`)
			}
			await sleep(500)
			setPreLoader(false)
		})()
	}, [])

	const value: ProviderContextType = useMemo(
		() => ({
			setGlobalLoader,
			setSnackbar,
			setDialog,
			activeBreadcrumb,
			setActiveBreadcrumb,
		}),
		[setGlobalLoader, setSnackbar, setDialog]
	)

	return (
		<AppContext.Provider value={value}>
			<GlobalProgress isLoading={globalLoader || preLoader} />
			{preLoader ? <Splash /> : children}
			{dialog && <AlertDialog {...dialog} setDialog={setDialog} />}
			<MuiSnackbar
				anchorOrigin={{ vertical: snackbar.vertical ?? 'bottom', horizontal: snackbar.horizontal ?? 'left' }}
				open={snackbar.open}
				autoHideDuration={__DEV__ ? 120000 : 6000}
				onClose={snackbarHandleClose}
				sx={{
					width: `100%`,
					maxWidth: isMobile ? `calc(100vw - 48px)` : '420px',
					minWidth: `350px`,
					color: colors.white,
				}}>
				<Alert
					variant='outlined'
					onClose={snackbarHandleClose}
					severity={snackbar.severity}
					iconMapping={alertIconMapping()}
					classes={{
						colorSuccess: `white`,
					}}
					sx={{ width: '100%', color: colors.text }}>
					{snackbar.message}
				</Alert>
			</MuiSnackbar>
		</AppContext.Provider>
	)
})

export { Provider }
