import { Container } from '@mui/material'
import { FormAuthLogin } from 'components/form-controlled/login/form'
import { useAppContext } from 'context/Provider'
import { RegistrationFooter } from 'pages/auth/components/register-footer'
import React, { useEffect } from 'react'

export default function PageFellowshipLoginCredentials() {
	const { setSnackbar } = useAppContext()

	useEffect(() => {
		// Hack: Need to find a better approach supporting other pages
		const resetPassword = localStorage.getItem('reset-password')
		if (resetPassword === 'true') {
			localStorage.removeItem('reset-password')
			setSnackbar({
				open: true,
				message: 'Password reset successful',
				severity: `success`,
				vertical: 'top',
				horizontal: 'center',
			})
		}
	}, [])
	return (
		<React.Fragment>
			<Container maxWidth={`xs`}>
				<FormAuthLogin fellowship={true} />
			</Container>
			<RegistrationFooter />
		</React.Fragment>
	)
}
