import { Container, Typography } from '@mui/material'
import { theme } from 'assets/material/theme'
import { Box } from 'components/box/box'
import { Button } from 'components/button/button'
import { Row } from 'components/grid'
import { Illustration } from 'components/illustration'
import { Spacer } from 'components/layout/spacer'
import { Support } from 'components/support'
import { useAppContext } from 'context/Provider'
import { useStorage } from 'hooks/useStorage'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuthService } from 'services/user'

interface CfeGuestProps {}

export default function Component(props: CfeGuestProps) {
	const navigate = useNavigate()
	const { setSnackbar } = useAppContext()
	const { setStorage } = useStorage()
	const params = new URLSearchParams(window.location.search)
	const token = params.get('token')
	const { userVerifyFellowshipInvite } = useAuthService()
	const [isFellowship, setIsFellowship] = useState<boolean | undefined>(undefined)

	const checkUserStatus = async () => {
		const response = await userVerifyFellowshipInvite(token)
		if (response.error) {
			setSnackbar({
				open: true,
				message: response?.error?.message || response?.message || `An error occurred`,
				severity: `error`,
			})
		} else {
			setStorage('flw_response', response)
			setIsFellowship(response.registered)
		}
	}

	useEffect(() => {
		if (!token && isFellowship === undefined) {
			navigate('/')
		}

		if (token) {
			checkUserStatus()
		}
	}, [token])

	useEffect(() => {
		if (isFellowship === undefined) {
			return
		}
		if (isFellowship === false) {
			navigate('/fellowship/register')
		}
		if (isFellowship === true) {
			navigate('/fellowship/login')
		}
	}, [isFellowship])

	return (
		<React.Fragment>
			<Container maxWidth={`xs`}>
				<Spacer height={theme.spacing(3)} />
				<Row justifyContent={`center`}>
					<Box>
						<Illustration name={`graduationOpenDoor`} width={`100%`} />
					</Box>
				</Row>
				<Spacer height={theme.spacing(4)} />
				<Typography variant={`h3`} fontWeight={600} textAlign={`center`}>
					Absa Fellowship
				</Typography>

				<Typography variant={`medium`} component='div' textAlign={'center'}>
					Value proposition, Lorem ipsum dolor sit amet, lorem ipsum dolor sit amet consectetur.
				</Typography>

				<Spacer height={theme.spacing(4)} />
				{!isFellowship && isFellowship !== undefined && (
					<>
						<Button
							onClick={() => {
								navigate('/fellowship/register')
							}}
							variant={`contained`}
							color={`secondary`}>
							Create Account
						</Button>
						<Spacer height={theme.spacing(2)} />
						<Typography variant={`medium`} component='div' textAlign={'center'}>
							or
						</Typography>
						<Spacer height={theme.spacing(2)} />
					</>
				)}
				{isFellowship && (
					<>
						<Button
							variant={`outlined`}
							color={`secondary`}
							onClick={() => {
								navigate('/fellowship/login')
							}}>
							Login
						</Button>
						<Spacer height={theme.spacing(4)} />
					</>
				)}
			</Container>
			<Spacer height={theme.spacing(4)} />
			<Support maxWidth='xl' type='flw' />
		</React.Fragment>
	)
}
